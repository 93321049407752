import React, { useState } from 'react';
import {
  Project,
  ProjectStatus,
  ProjectStatusCount,
  ProjectStatusValues,
} from 'state/types';

export const tabColors = {
  ALL: 'border-blue-800 text-blue-800 hover:text-blue-900',
  ACTIVE: 'border-green-800 text-green-800 hover:text-green-900',
  'PENDING PROPOSAL': 'border-red-800 text-red-800 hover:text-red-900',
  'PROPOSAL SENT': 'border-amber-800 text-amber-800 hover:text-amber-900',
  COMPLETE: 'border-gray-700 text-gray-800 hover:text-gray-900',
  LOST: 'border-orange-800 text-orange-800 hover:text-orange-900',
};

export const chipColors = {
  ALL: 'text-blue-800 bg-blue-100',
  ACTIVE: 'text-green-800 bg-green-100',
  'PENDING PROPOSAL': 'text-red-800 bg-red-100',
  'PROPOSAL SENT': 'text-amber-800 bg-amber-100',
  COMPLETE: 'text-gray-800 bg-gray-100',
  LOST: 'text-orange-800 bg-orange-100',
};

type Props = {
  statuses: Project['status'][];
  status: ProjectStatusValues;
  projects: ProjectStatus;
  isEditingProjectRow: boolean;
  handleStatusClick: (status: Project['status']) => void;
  statusCount: ProjectStatusCount;
};

export const StatusBar = ({
  statuses,
  status,
  isEditingProjectRow,
  handleStatusClick,
  statusCount,
}: Props) => {
  const [selectedProjectStatus, setSelectedProjectStatus] = useState(status);

  const warnDisabledClick = () => {
    alert("You can't perform this action while editing a project row");
  };

  return (
    <ul role="tablist" className="w-full flex items-center gap-x-6">
      {statuses.map((status, idx) => {
        return (
          <li
            key={status}
            className={`flex py-2 border-b-2 ${selectedProjectStatus === status ? tabColors[status] : 'border-white text-gray-500'}`}
          >
            <button
              role="tab"
              aria-selected={selectedProjectStatus === status ? true : false}
              aria-controls={`tabpanel-${idx + 1}`}
              className={`${isEditingProjectRow ? 'cursor-not-allowed' : ''} px-2 rounded-lg duration-150 ${selectedProjectStatus === status ? tabColors[status] : null}`}
              onClick={
                isEditingProjectRow
                  ? warnDisabledClick
                  : () => {
                      setSelectedProjectStatus(status);
                      handleStatusClick(status);
                    }
              }
            >
              <span className="text-sm">{status.toUpperCase()}</span>
            </button>
            <div className="font-medium whitespace-nowrap">
              {/* TODO: DARKMODE: dark:bg-gray-800 */}
              <div
                className={`inline px-3 py-1 text-[10px] font-normal rounded-full gap-x-2 ${chipColors[status]}`}
              >
                {statusCount[status]}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
