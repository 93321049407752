import React, { useContext, useEffect, useState } from 'react';
import type { CellContext, RowData } from '@tanstack/react-table';
import { DataTableContext, DataTableHandlersContext } from './hooks';

type EditableCellProps<TData extends RowData> = CellContext<TData, unknown> & {
  useTextArea?: boolean;
  inputType?: 'text' | 'number' | 'currency' | 'otherCurrency';
  formatter?: (value: number | string | null) => string;
  className?: string;
};

export const EditTextCell = <TData extends RowData>({
  getValue,
  row,
  column,
  table,
  inputType = 'text',
  formatter,
  useTextArea,
  className,
}: EditableCellProps<TData>) => {
  const { getRow, removeRowFromReset } = useContext(DataTableHandlersContext);
  const rowToReset = getRow(Number(row.id));
  const initialValue = getValue();
  const [value, setValue] = useState<number | string | null>(
    typeof initialValue === 'number' || typeof initialValue === 'string'
      ? initialValue
      : null
  );
  const formatValue = (val: number | string | null) => {
    if (val === null || val === '') return '';
    if (inputType === 'currency') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(val));
    } else if (formatter) {
      return formatter(val);
    } else if (inputType === 'otherCurrency') {
      return Number(val).toLocaleString();
    } else if (inputType === 'number') {
      return val.toString();
    }
    return val;
  };

  const [displayValue, setDisplayValue] = useState<string | number>(
    value ? formatValue(value) : ''
  );
  const [originalValue, setOriginalValue] = useState<string | number | null>(
    value
  );

  const parseValue = (val: string | number | null) => {
    if (val === '' || val === null) return null;
    if (
      inputType === 'currency' ||
      inputType === 'number' ||
      inputType === 'otherCurrency'
    ) {
      return parseFloat(String(val).replace(/[^0-9.-]+/g, '')) || 0;
    }
    return val;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue === '' ? null : inputValue);
    setDisplayValue(inputValue);
  };

  const handleBlur = () => {
    const parsedValue = parseValue(value);
    if (parsedValue !== originalValue) {
      table.options.meta?.updateData(
        row.id,
        column.id as keyof TData,
        parsedValue as TData[keyof TData]
      );
      // const meta = table.options.meta
      setOriginalValue(parsedValue);
    }

    setDisplayValue(formatValue(parsedValue));
  };

  const handleFocus = () => {
    setDisplayValue(value ?? '');
  };

  useEffect(() => {
    if (typeof initialValue === 'number' || typeof initialValue === 'string') {
      setValue(initialValue);
      setDisplayValue(formatValue(initialValue));
      setOriginalValue(initialValue);
    } else {
      setValue(null);
    }
  }, [initialValue, inputType]);

  useEffect(() => {
    if (rowToReset === Number(row.id)) {
      if (
        typeof initialValue === 'number' ||
        typeof initialValue === 'string'
      ) {
        setValue(initialValue);
        setDisplayValue(formatValue(initialValue));
        setOriginalValue(initialValue);
      } else {
        setValue(null);
      }
      removeRowFromReset(Number(row.id));
    }
  }, [rowToReset]);

  return (
    <>
      {useTextArea ? (
        <textarea
          className="textarea text-sm h-full w-full resize-y leading-6"
          value={displayValue}
          onChange={(e) => {
            const inputValue = e.target.value;
            setValue(inputValue === '' ? null : inputValue);
            setDisplayValue(inputValue);
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          wrap="soft"
          rows={2}
        />
      ) : (
        <input
          value={displayValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className={`text-sm input input-sm w-full bg-inherit${` ${className}`} px-1`}
          type={inputType === 'currency' || 'number' ? 'text' : inputType}
        />
      )}
    </>
  );
};
