import React from 'react';
import ReactSelect, { components } from 'react-select';
import type {
  ActionMeta,
  SingleValue,
  MultiValue,
  MultiValueProps,
  OptionProps,
  MenuPlacement,
  StylesConfig,
  GroupBase,
  Props as ReactSelectProps,
} from 'react-select';
import { capitalizeWords } from '../utils';

type Size = 'sm' | 'md' | 'lg';

export type OptionType = {
  value: number | string | boolean | null;
  label: string;
};

export interface ExtendedReactSelectProps
  extends ReactSelectProps<OptionType, true> {
  multiDisplay?: 'chip' | 'selectedCount';
}

export interface SelectProps {
  options: OptionType[] | GroupBase<OptionType>[];
  value: OptionType[];
  onChange: (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  isMulti?: boolean;
  multiDisplay?: 'chip' | 'selectedCount';
  placeholder?: string;
  isDisabled?: boolean;
  includeToggleAll?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  size?: Size;
  menuPortalTarget?: HTMLElement | null;
  stylesOverride?: string;
  noOptionsMessage?: (msg: string) => string;
}

const TOGGLE_ALL_OPTION = { value: 'toggle_all', label: 'Toggle All' };
const BLUE_COLOR = '#5e88e5';

const CheckboxOption = (props: OptionProps<OptionType, true>) => {
  return (
    <>
      {props.data.value === TOGGLE_ALL_OPTION.value ? (
        <components.Option {...props}>
          <label className="cursor-pointer">{props.label}</label>
        </components.Option>
      ) : (
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            className="cursor-pointer"
            onChange={() => null}
          />
          <label className="ml-3 cursor-pointer">{props.label}</label>
        </components.Option>
      )}
    </>
  );
};

const CustomMultiValueRemove = () => {
  return null;
};

const CustomMultiValue = (props: MultiValueProps<OptionType, true>) => {
  const { multiDisplay } = props.selectProps as ExtendedReactSelectProps;

  if (multiDisplay === 'selectedCount' && props.index === 0) {
    const count = props.getValue().length;
    return (
      <components.MultiValue {...props}>
        {count > 0 ? `${count} selected` : null}
      </components.MultiValue>
    );
  }

  if (multiDisplay === 'chip') {
    return <components.MultiValue {...props} />;
  }

  return null;
};

export const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  isMulti = false,
  multiDisplay = 'selectedCount',
  placeholder = '',
  isDisabled = false,
  includeToggleAll = false,
  isClearable = false,
  menuPlacement = 'bottom',
  size = 'sm',
  stylesOverride,
  noOptionsMessage,
}) => {
  const flattenOptions = (
    opts: OptionType[] | GroupBase<OptionType>[]
  ): OptionType[] => {
    return Array.isArray(opts) && opts[0] && 'options' in opts[0]
      ? (opts as GroupBase<OptionType>[]).flatMap((group) => group.options)
      : (opts as OptionType[]);
  };

  const modifiedOptions = (
    includeToggleAll && isMulti ? [TOGGLE_ALL_OPTION, ...options] : options
  ).map((option) => {
    // Covers OptionType
    if ('value' in option) {
      return {
        label: capitalizeWords(option.label),
        value: option.value,
      };
    }
    // Leave GroupBase<OptionType> as is
    return {
      ...option,
      label: capitalizeWords(option.label),
    };
  });

  const handleChangeMulti = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (isMulti && onChange) {
      const flattenedOptions = flattenOptions(options);
      if (actionMeta.option?.value === TOGGLE_ALL_OPTION.value) {
        const isAllSelected = selectedOptions.length >= flattenedOptions.length;
        onChange(isAllSelected ? [] : flattenedOptions, actionMeta);
      } else {
        onChange(selectedOptions, actionMeta);
      }
    }
  };

  const handleChangeSingle = (
    selectedOption: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (!isMulti && onChange) {
      onChange(
        [selectedOption].filter(Boolean) as MultiValue<OptionType>,
        actionMeta
      );
    }
  };

  const getStyles = (size: Size): StylesConfig<OptionType, boolean> => ({
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '12px',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? BLUE_COLOR : 'inherit',
      // fontSize: size === 'sm' ? '14px' : size === 'md' ? '16px' : '16px',
      fontWeight: 400,
      height: size === 'lg' ? '48px' : size === 'md' ? '38px' : '24px',
      lineHeight: '1.25',
      // minHeight: size === 'lg' ? '48px' : size === 'md' ? '38px' : '32px',
      '&:hover': {
        borderColor: state.isFocused ? BLUE_COLOR : 'inherit',
      },
      fontSize: '12px',
      paddingTop: '1px',
      display: 'flex',
      justifyContent: 'center',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: size === 'lg' ? '8px' : size === 'md' ? '8px' : '4px',
      fontSize: '12px',
    }),
    menu: (provided) => ({
      ...provided,
      // fontSize: size === 'sm' ? '14px' : size === 'md' ? '16px' : '16px',
      fontWeight: 400,
      overflow: 'hidden',
      zIndex: 99,
      fontSize: '12px',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 500,
      fontSize: '12px',
      padding: '2px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      backgroundColor: state.isFocused ? BLUE_COLOR : 'white',
      paddingBottom: size === 'lg' ? '6px' : size === 'md' ? '4px' : '0px',
      paddingTop: size === 'lg' ? '6px' : size === 'md' ? '4px' : '0px',
      // padding: size === 'lg' ? '6px' : size === 'md' ? '4px' : '0px',
      fontSize: '12px',
    }),
  });

  const styles = getStyles(size);

  const componentsProp = {
    Option: CheckboxOption,
    MultiValue: CustomMultiValue,
    ...(multiDisplay === 'selectedCount' && {
      MultiValueRemove: CustomMultiValueRemove,
    }),
  };

  return isMulti ? (
    <ReactSelect<OptionType, true, GroupBase<OptionType>>
      {...{
        options: modifiedOptions,
        value,
        onChange: handleChangeMulti,
        isMulti,
        isDisabled,
        placeholder,
        isClearable,
        menuPlacement,
        styles,
        multiDisplay,
        menuPortalTarget: document.body,
      }}
      options={modifiedOptions}
      className={`basic-multi-select w-full capitalize ${stylesOverride}`}
      classNamePrefix="select"
      components={componentsProp}
      hideSelectedOptions={!isMulti}
      closeMenuOnSelect={!isMulti}
    />
  ) : (
    <ReactSelect<OptionType, false, GroupBase<OptionType>>
      options={modifiedOptions}
      className={`basic-multi-select w-full capitalize ${stylesOverride}`}
      classNamePrefix="select"
      styles={styles}
      onChange={handleChangeSingle}
      value={value}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
    />
  );
};
