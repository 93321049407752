import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetAuthState,
  resetProjectsApi,
  setLogin,
} from '../../state/services';
import { resetStore } from '../../state/store';
import { Login } from '../../state/types';
import { useGetNavigation } from '../../utils/hooks';
import { useLogin, useLogout } from './useLoginLogout';
import { flushStore } from '../../state/store';

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { navigate } = useGetNavigation();
  const { login } = useLogin();
  const { logout } = useLogout();
  const dispatch = useDispatch();

  const handleLoginAll = async (body: Login) => {
    try {
      await flushStore();
      setIsLoading(true);

      const response = await login(body);
      dispatch(setLogin(response));

      navigate('/projects');
      setIsLoading(false);
      return response;
    } catch (err) {
      console.error(err);
      throw new Error(
        'Login failed. Please try again or contact customer support.'
      );
    }
  };

  const handleLogoutAll = async () => {
    try {
      setIsLoading(true);
      // TODO: reset cached data...resetAuthState doesn't work lmao. Or review how we handle all this.
      await logout();
      dispatch(resetAuthState());
      resetProjectsApi();
      setIsLoading(false);
      await resetStore();
    } catch (err) {
      console.error(err);
      throw new Error('Logout failed.');
    }
  };

  return { handleLoginAll, handleLogoutAll, isLoading };
};
