import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../state/services';
import { createOptions, getApiUrl } from '../utils';
import { useGetNavigation } from '../utils/hooks';

const convertStringRowToArray = (row: string) => {
  if (typeof row !== 'string') {
    throw new Error(
      `Paster utils::convertStringRowToArray - Given row is not a string: ${row}`
    );
  }
  return row.split(String.fromCharCode(9));
};

export const convertToBothAsHeaders = (dataList) => {
  if (!Array.isArray(dataList)) {
    throw new Error(
      `Paster utils::convertHeaders - Given data list is not an array: ${dataList}`
    );
  }

  const dataToReturn = [];
  const headers = {
    row: [],
    column: [],
  };
  const firstRow = convertStringRowToArray(dataList[0]);

  // Assign top level key
  for (let i = 0; i < firstRow.length; i++) {
    dataToReturn.push({
      [firstRow[i]]: [],
    });
  }

  for (let i = 1; i < dataList.length; i++) {
    const listToIterate = convertStringRowToArray(dataList[i]);
    if (listToIterate.length !== firstRow.length) {
      throw new Error(
        'Number of headers do not match the number of columns in the data'
      );
    }
    for (let listIndex = 0; listIndex < listToIterate.length; listIndex++) {
      const columnHeader = Object.keys(dataToReturn[listIndex])[0];
      const column = dataToReturn[listIndex][columnHeader];
      column.push(listToIterate[listIndex]);
    }
  }

  const columnHeader = dataToReturn[0][firstRow[0]];
  headers.row = [...firstRow];
  headers.column = [...columnHeader];

  return [dataToReturn, headers];
};

// Refactor to reduce runtime from Ologn2
export const convertToColumnHeaders = (dataList) => {
  // console.log('dataList', dataList)
  const updatedData = {};
  dataList.forEach((row, rowIndex) => {
    const rowKey = rowIndex + 1;
    const columnData = convertStringRowToArray(row); // array of each data point
    const rowValue = {};
    // console.log('columnData', columnData)
    // Add up to 8 columns
    // if (!rowIndex) {
    //   columnData.forEach((data) => {
    //     // eslint-disable-next-line no-unused-expressions
    //     updatedData[data];
    //   });
    // }

    columnData.forEach((data, columnDataIndex) => {
      const columnDataKey = columnDataIndex + 1;
      rowValue[columnDataKey] = data;
    });
    updatedData[rowKey] = rowValue;
  });

  return updatedData;
};

export const convertToRowHeaders = (dataList) => {
  if (!Array.isArray(dataList)) {
    throw new Error(
      `Paster utils::convertHeaders - Given data list is not an array: ${dataList}`
    );
  }
  const dataToReturn = [];
  const firstRow = convertStringRowToArray(dataList[0]);

  // Assign top level key
  for (let i = 0; i < firstRow.length; i++) {
    dataToReturn.push({
      [firstRow[i]]: [],
    });
  }

  // [{}, {}, {}], where each object represents a column
  for (let i = 1; i < dataList.length; i++) {
    const row = convertStringRowToArray(dataList[i]);
    if (row.length !== firstRow.length) {
      throw new Error(
        'Number of headers do not match the number of columns in the data'
      );
    }
    let columnOrder = firstRow.length;

    // Take each element from row. Get column and columnHeader. Push to end of column array.
    while (columnOrder) {
      const column = firstRow.length - columnOrder;
      const columnHeader = Object.keys(dataToReturn[column])[0];
      dataToReturn[column][columnHeader].push(row[column]);
      columnOrder--;
    }
  }
  return dataToReturn;
};

export const CsvUploadPage = () => {
  const [csvData, setCsvData] = useState(null);
  const { navigate } = useGetNavigation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const csv = event.target.result;
      setCsvData(csv);
    };
    reader.readAsText(file);
  };

  const handleUpload = async () => {
    try {
      // Send CSV data to server via API request
      const formData = new FormData();
      formData.append('csv_file', csvData);
      const response = await fetch(
        getApiUrl('projects/upload_csv'),
        createOptions('POST', {
          body: { csv_file: csvData },
        })
      );
      const jsoned = await response.json();
      if (!jsoned.success) {
        alert('error with uploading CSV...');
        return;
      }
      navigate('/projects');
    } catch (error) {
      console.error('Error uploading CSV:', error);
      alert('error with uploading CSV...');
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col w-[500px] mx-auto">
        <h3>Please upload your csv file below</h3>
        <input
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileChange}
        />
        <button
          onClick={handleUpload}
          disabled={!csvData}
          className="px-3 py-1.5 text-white bg-emerald-800 rounded-lg duration-150 hover:bg-sky-500 active:shadow-lg"
        >
          Upload CSV file only
        </button>
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-12">
        <h3>
          See below for an example of what the CSV template should look like
        </h3>
        <img
          alt="example csv upload"
          src={
            'https://lh3.googleusercontent.com/pw/AP1GczNlCKaef7Vca6pZCWGeIqaZ78mwxE44RqSbI45PpP2LK6qe-sz1kPUSWeG_MMpJwW5RiIu1h3eZ_Mt2301CasYvvU0tXmWNgO7fAcc-T0G5SBVQt1-DyE815GanP75DgrqhxiDc9_0phKDiGLHSi-unkg=w1246-h188-s-no-gm'
          }
          style={{ width: '75%', maxHeight: '75%' }}
        />
        <p className="mt-16">Ensure the following:</p>
        <ul className="text-left list-disc">
          <li className="text-base">
            Make sure the headers are case sensitive, as shown. Each word should
            be capitalized
          </li>
          <li className="text-base">
            Ensure the first row is the header (the names of the columns)
          </li>
          <li className="text-base">
            Ensure that in the currency columns (Fees, Awarded), that there are
            only $ sign and comma for non number inputs
          </li>
        </ul>
      </div>

      {/* <div className="mb-4 pb-4 border-b-2 w-11/12 mx-auto">
        <div>
          Copy paste entire spreadsheet into the single input entry below:
        </div>
        <input placeholder="Copy/paste here" className="w-[300px] h-[50px] border-2" onPaste={handlePaste} />
      </div> */}
      {/* {
        isFilled ? (
          <div className="px-2 w-full mx-auto flex flex-col">

            <div className="flex m-0 bg-gray-50 text-gray-600 font-medium border-b mx-auto">
              <>
                {
                  csv.map((data) => {
                    const headerName = Object.keys(data)[0]
                    return (
                      <>
                        {
                          <>
                            <h4 key={headerName} className="break-words text-left w-[120px] py-3 border-2">{headerName}</h4>
                          </>
                        }
                      </>
                    )
                  })
                }
              </>
            </div>
            <div className="flex m-0 bg-gray-50 text-gray-600 font-medium border-b mx-auto">
              {Object.keys(body).map((key) => {
                return (
                  <div className="flex flex-col">
                    {body[key].map((bodyKey) => {
                      return (
                        <>
                          {bodyKey.map((bodyData) => {
                            return (
                              <input
                                className="break-words w-[120px] border-2 border-black"
                                type="text"
                                value={bodyData}
                                onPaste={handlePaste}
                              />
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null
      } */}
    </div>
  );
};
