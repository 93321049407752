import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Firm, User, UserResponse } from '../types';
import { IRootState } from 'state/store';

export interface AuthState {
  firm: Firm;
  user: User;
  isLoggedIn: boolean;
}

export const ALLOWED_ROLES = ['admin', 'user'];

// TODO: compariosn doesn't work because of camel vs snake casing here. Update...
export const isUserEmpty = (user: UserResponse) => {
  return (
    JSON.stringify(user) ===
    JSON.stringify({
      firstName: '',
      lastName: '',
      email: '',
    })
  );
};

const initialState: AuthState = {
  firm: {
    name: '',
    unit: '',
    street_address: '',
    city: '',
    province: '',
    country: '',
    postal_code: '',
    email: '',
    phone: '',
    // TODO: maybe separate out users?
    users: [],
    fiscal_start: 1,
  },
  // This typing is incorrect compared to server response
  user: {
    first_name: '',
    last_name: '',
    email: '',
    role: 'editor',
  },
  userType: 'editor',
};
// TODO: Consolidate users and auth?
// TODO: Make this into api slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin: (
      state,
      { payload }: { payload: Omit<AuthState, 'isLoggedIn'>; type: string }
    ) => {
      state.firm = payload.firm;
      state.user = payload.user;
      state.isLoggedIn = true;
      state.userType = payload.user.role;
    },
    resetAuthState: () => {
      return initialState;
    },
    setUsersForFirm: (state, { payload }: { payload: User[] }) => {
      state.firm.users = payload;
    },
  },
});

export const { setLogin, resetAuthState, setUsersForFirm } = authSlice.actions;

// Selector to get the entire auth state
export const selectAuth = (state: IRootState) => state.auth;

// Selector to get the firm data from the auth state
export const selectFirm = createSelector(
  selectAuth,
  // TODO: remove type force
  (auth) => auth.firm as Firm
);

// Selector to get the user data from the auth state
export const selectUser = createSelector(selectAuth, (auth) => {
  return auth.user as UserResponse;
});

// Selector to get the login status from the auth state
export const selectIsLoggedIn = createSelector(selectAuth, (auth) => {
  if (!auth.user) {
    return false;
  }
  return (
    auth.user.first_name.length > 0 &&
    auth.user.last_name.length > 0 &&
    auth.user.email.length > 0
  );
});

export const selectUserType = createSelector(
  selectAuth,
  (auth) => auth.userType
);
