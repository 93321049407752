import React, { useEffect, useState, type ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectFirm } from './state/services';
import { Toast } from './components/toast';
import { useUpdateDocumentTitle } from './hooks';

const AlphaBanner = ({ close }: { close: () => void }) => {
  return (
    <div className="fixed w-full bottom-0 isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 h-[54px]">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#16A34A] to-[#0F8A38] opacity-20"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        ></div>
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#16A34A] to-[#0F8A38] opacity-20"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        ></div>
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Lampside 2024</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx="1" cy="1" r="1" />
          </svg>
          This web application is currently in Early Access mode.{' '}
          <a
            href="mailto:lampsidesoftware@gmail.com?subject=Lampside Customer Support&body=Hi, there is an issue I would like to report. [REPLACE THIS TEXT WITH YOUR ISSUE]."
            className="underline"
          >
            Please report any issues here by clicking this link.
          </a>
        </p>
        {/* <a href="#" className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Register now <span aria-hidden="true">&rarr;</span></a> */}
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={close}
        >
          <span className="sr-only">Dismiss</span>
          <svg
            className="h-5 w-5 text-gray-900"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export const App = ({ children }: { children: ReactNode }) => {
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const firm = useSelector(selectFirm);
  const isMotus = firm.email.includes('motuseng.ca');
  const isDev = process.env.NODE_ENV === 'development';
  const { updateDocumentTitle } = useUpdateDocumentTitle();

  useEffect(() => {
    updateDocumentTitle(firm.name !== '' ? firm.name : 'Lampside');
  }, [firm.name]);

  return (
    <div className="App w-full bg-white flex flex-col">
      <div className="flex-1 bg-white shadow-lg shadow-t-xl rounded-lg mx-auto w-full pb-12">
        {children}
      </div>
      {isBannerOpen && !isMotus ? (
        <AlphaBanner
          close={() => {
            setIsBannerOpen(false);
          }}
        />
      ) : null}
      <Toast />
    </div>
  );
};
