import React, { useState } from 'react';
import { Avatar } from '../avatar';

type InfoType = {
  heading: { key: string; value: string; imgSrc?: string };
  information: Record<string, string>;
  handleInfoEdit?: () => void;
};

export const Info = ({ heading, information, handleInfoEdit }: InfoType) => {
  console.log('heading', heading);
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);
  return (
    <div className="max-w-screen-lg mx-auto border-b my-4">
      <div className="flex items-start justify-between p-4">
        <div className="flex flex-col items-start space-y-2">
          <div>
            <div className="flex gap-4">
              <Avatar imgSrc={heading.imgSrc} altValue={heading.value} />
              <div>
                <h4 className="text-left text-gray-800 font-semibold">
                  <span className="capitalize">{heading.key}: </span>{' '}
                  {heading.value}
                </h4>
                <p className="text-left text-gray-800 text-xl">
                  GST Number: {information.gst_number}
                </p>
              </div>
            </div>
          </div>
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={() => {
                  setShowDetailedInfo(!showDetailedInfo);
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-emerald-800" />
              <span className="ms-3 text-sm font-medium text-gray-900">
                Show detailed information
              </span>
            </label>
          </div>
          {showDetailedInfo &&
            Object.keys(information).map((info) => {
              return (
                <p className="text-gray-600 text-sm">
                  <span className="capitalize">{info}</span>:{' '}
                  {information[info]}
                </p>
              );
            })}
        </div>
        {handleInfoEdit ? (
          <div className="px-4 text-right">
            <button
              type="button"
              className="text-base px-6 py-3 text-white duration-100 bg-emerald-800 rounded-lg shadow-md focus:shadow-none ring-offset-2 ring-blue-600 focus:ring-2"
              onClick={handleInfoEdit}
            >
              Edit coming soon
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
