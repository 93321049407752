import React from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { FormattedProject } from 'state/types';

import { EditTextCell } from '../../../components/react-table';
import { EditSelectCell } from '../../../components/react-table/edit-select-cell';
import { tabColors } from '../status-bar';

const columnHelper = createColumnHelper<FormattedProject>();

const selectFilterFn = (
  row: Row<FormattedProject>,
  colId: string,
  filterValue: (string | number | null)[]
) => {
  const rowValue: string | number | null = row.getValue(colId);
  console.log({ row, colId, rowValue, filterValue });
  if (Array.isArray(filterValue)) {
    return filterValue.includes(rowValue);
  }
  return true;
};

const numberFilterFn =
  (projectProp: 'fee' | 'awarded' | 'project_number') =>
  (row: Row<FormattedProject>, _colId: string, filterValue: string) => {
    const numberFilterValue = Number(filterValue);
    if (isNaN(numberFilterValue)) {
      return false;
    }
    if (row.original[projectProp].toString().includes(filterValue)) {
      return true;
    }
    return false;
  };

export const projectsColumns = [
  columnHelper.accessor('project_number', {
    header: 'Project #',
    enableGrouping: true,
    cell: (props) => {
      return <EditTextCell {...props} inputType="number" />;
    },
    maxSize: 7,
    filterFn: numberFilterFn('project_number'),
  }),
  columnHelper.accessor('account_name', {
    header: 'Account',
    cell: (props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      const path = `/account?accountId=${props.row.original.account_id}`;
      return (
        <button
          type="button"
          className="text-left text-sm"
          onClick={() => {
            navigate(path);
          }}
        >
          {props.getValue()}
        </button>
      );
    },
    maxSize: 13,
  }),
  columnHelper.accessor('site', {
    header: 'Project Site',
    cell: (props) => <EditTextCell {...props} />,
    maxSize: 20,
  }),
  columnHelper.accessor('site_address', {
    header: 'Site Address',
    cell: (info) => {
      return (
        <div>
          <textarea
            className="cursor-pointer text-sm bg-white w-full"
            value={info.getValue()}
            rows={2}
            wrap="hard"
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      );
    },
    maxSize: 25,
  }),
  columnHelper.accessor('project_type', {
    header: 'Project Type',
    cell: (props) => <EditSelectCell {...props} />,
    meta: {
      filterVariant: 'select-text',
      optionsKey: 'projectTypes',
    },
    filterFn: selectFilterFn,
    size: 15,
    maxSize: 20,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (props) => {
      const stylesOverride = tabColors[props.getValue()];
      return <EditSelectCell stylesOverride={stylesOverride} {...props} />;
    },
    meta: {
      filterVariant: 'select-text',
      optionsKey: 'statuses',
    },
    size: 12,
    maxSize: 20,
  }),
  columnHelper.accessor('fee', {
    header: 'Fee',
    cell: (props) => <EditTextCell {...props} inputType="currency" />,
    filterFn: numberFilterFn('fee'),
    maxSize: 10,
  }),
  columnHelper.accessor('awarded', {
    header: 'Awarded',
    cell: (props) => <EditTextCell {...props} inputType="currency" />,
    filterFn: numberFilterFn('awarded'),
    maxSize: 10,
  }),
  columnHelper.accessor('comments', {
    header: 'Comments',
    cell: (props) => {
      const value = props.getValue();
      return (
        <div className="h-[20px] overflow-scroll">
          <div
            className={`text-sm cursor-pointer ${value === null ? 'h-[20px]' : 'h-auto'}`}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </div>
      );
    },
    maxSize: 15,
  }),
  columnHelper.accessor('updated_at', {
    header: 'Last modified',
    cell: (props) => {
      const date = new Date(props.getValue());
      return (
        <div>
          <span className="text-sm">
            {date.toLocaleDateString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              // timeZoneName: '',
            })}
          </span>
        </div>
      );
    },
    maxSize: 20,
  }),
  // columnHelper.accessor('fiscal_year', {
  //   header: 'Fiscal Year',
  //   cell: (info) => info.getValue(),
  //   size: 30,
  // }),
  // columnHelper.accessor('quarter', {
  //   header: 'Quarter',
  //   cell: (info) => info.getValue(),
  //   size: 30,
  // }),
];
