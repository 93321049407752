import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import type { Payment } from 'state/types';

import {
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
} from '../../../state/services';
import { toast } from 'react-toastify';

type PaymentInputProps = {
  payment?: Payment;
  invoice_id: number;
  invoiceAmount: number;
  triggerPaymentCancelled: ({
    id,
    invoice_id,
  }: {
    id: number;
    invoice_id: number;
  }) => void;
  triggerPaymentSaved: () => void;
};

const getNowInTimestamp = () => {
  const now = new Date();
  // Step 2: Create a new Date object with the same date but time set to midnight
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );

  // Step 3: Convert the date with time set to midnight to Unix timestamp
  const timestamp = Math.floor(midnight.getTime());
  return timestamp;
};

export const PaymentInput = ({
  payment,
  invoice_id,
  invoiceAmount,
  triggerPaymentCancelled,
  triggerPaymentSaved,
}: PaymentInputProps) => {
  const isPaid = !!payment?.id;
  // This is to prevent local machine javascript conversion from the date saved in db
  const date = payment?.payment_date
    ? new Date(payment.payment_date).getTime()
    : getNowInTimestamp();
  const [createPayment] = useCreatePaymentMutation({
    fixedCacheKey: 'invoices-and-payments',
  });
  const [updatePayment] = useUpdatePaymentMutation({
    fixedCacheKey: 'invoices-and-payments',
  });
  const [paymentDate, setPaymentDate] = useState(date);
  const [isChecked, setIsChecked] = useState(isPaid);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (date === paymentDate && isPaid === isChecked) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [date, isChecked, isPaid, paymentDate]);

  console.log(payment?.payment_amount, payment);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const savePayment = async () => {
    if (!isChecked) {
      if (isPaid) {
        triggerPaymentCancelled({
          id: payment.id,
          invoice_id,
        });
        return;
      }
    }

    const payload = {
      invoice_id,
      payment_amount: invoiceAmount,
      payment_date: paymentDate / 1000,
    };
    try {
      if (!!payment?.id) {
        await updatePayment({
          ...payload,
          id: payment.id,
        }).unwrap();
      } else {
        await createPayment(payload).unwrap();
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to save payment');
      return;
    }
    triggerPaymentSaved();
  };

  return (
    <>
      <div className="flex flex-col justify-between items-center px-4 gap-1">
        <div className="flex w-full gap-1 items-center">
          <div>
            <div className="flex justify-center items-center gap-2">
              <label
                htmlFor="fully-pay"
                className="text-base text-left text-gray-800 font-medium"
              >
                Received?
              </label>
              <input
                type="checkbox"
                name="fully-pay"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="invoice-and-payments-datepicker">
              <DatePicker
                selected={paymentDate}
                onChange={(date) => {
                  const unixTimestamp = new Date(date).getTime();
                  setPaymentDate(unixTimestamp);
                  // setCanSave(true)
                }}
              />
            </div>
          </div>
          <button
            onClick={canSave ? savePayment : null}
            type="button"
            className={
              canSave
                ? `relative w-1/4 ml-2 px-2 py-2 text-sm font-medium text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40`
                : 'inline-block px-4 py-2 text-white duration-150 font-medium bg-gray-400 rounded-lg cursor-not-allowed md:text-sm'
            }
          >
            Save
            {canSave ? (
              <div className="absolute top-[-4px] right-[-4px]">
                <span className="relative flex h-4 w-4">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-300 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-4 w-4 bg-sky-300"></span>
                </span>
              </div>
            ) : null}
          </button>
        </div>
      </div>
    </>
  );
};
