import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Invoice, Payment, Project } from 'state/types';
import { Close } from '../../../components/icons';
import { Modal } from '../../../shared';
import type { GetInvoicesResponse } from '../../../state/services';
import {
  useCancelInvoiceMutation,
  useCancelPaymentMutation,
  useCreateAwardedMutation,
  useCreateMultipleInvoicesMutation,
  useLazyGetInvoiceQuery,
  useUpdateProjectMutation,
  useGetAwardedQuery,
  useLazyGetProjectByIdQuery,
} from '../../../state/services';
import { currencyFormatter } from '../../../utils';
import { useListenForKeyboardPress } from '../../../utils/hooks';

import { PaymentInput } from './payment-input';
import './invoice-and-payments.css';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

export type Address = {
  unit: string;
  street_address: string;
  city: string;
  province: string;
  postal_code: string;
  country: string;
};

const TableBody = ({
  invoice,
  payment,
  totalInvoicedFee,
  awardedValue,
  triggerPaymentCancelled,
  triggerPaymentSaved,
  cancelInvoice,
}: {
  invoice: Invoice;
  payment: Payment;
  totalInvoicedFee: number;

  awardedValue: number;
  triggerPaymentCancelled: ({
    id,
    invoice_id,
  }: {
    id: number;
    invoice_id: number;
  }) => void;
  triggerPaymentSaved: () => void;
  cancelInvoice: (invoiceId: number) => void;
}) => {
  return (
    <>
      <tr className="flex-table row" role="rowgroup">
        <td className="text-gray-700 text-base flex-row first" role="cell">
          {invoice.invoiced_date}
        </td>
        <td className="text-gray-700 text-base flex-row" role="cell">
          {currencyFormatter().format(invoice.invoice_amount)} (
          {((invoice.invoice_amount / awardedValue) * 100).toFixed(2)}%)
        </td>
        <td className="text-gray-700 text-base flex-row" role="cell">
          {currencyFormatter().format(totalInvoicedFee)} (
          {((totalInvoicedFee / awardedValue) * 100).toFixed(2)}%)
        </td>
        <td className={`flex-row text-base`} role="cell">
          <PaymentInput
            payment={payment}
            invoice_id={invoice.id}
            invoiceAmount={invoice.invoice_amount}
            triggerPaymentSaved={triggerPaymentSaved}
            triggerPaymentCancelled={triggerPaymentCancelled}
          />
        </td>
        {/* <td className="text-gray-800 text-base flex-row" role="cell">
          <button
            type="button"
            onClick={() => {
              cancelInvoice(invoice.id);
            }}
            className="relative w-full px-2 py-2 text-sm font-medium text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          >
            Delete Invoice
          </button>
        </td> */}
      </tr>
    </>
  );
};

const totalInvoiceFee = (invoices: Invoice[], indexToStop) => {
  const invoicesToCalculate = invoices.slice(0, indexToStop + 1);
  return invoicesToCalculate.reduce(
    (accum, invoice) =>
      accum + parseFloat(invoice.invoice_amount as unknown as string),
    0
  );
};

const totalPaymentAmount = (invoices: GetInvoicesResponse['invoices']) => {
  return invoices.reduce((total, { payments }) => {
    // Iterate over payments array for each invoice
    payments.forEach(({ payment_amount }) => {
      total += parseFloat(payment_amount as unknown as string); // it's number type (and decimal in rails), but gets serialized
    });
    return total;
  }, 0);
};

const DEFAULT_INVOICE = { invoiced_date: Date.now(), invoice_amount: 0 };

type Props = {
  projects: Project[];
  // totalFee: number;
  projectId: number;
  // updateFee: (arg: number) => void;
  closeModal: () => void;
};

export const InvoiceAndPaymentsModal = ({
  projects,
  projectId,
  closeModal,
}: Props) => {
  const project = projects.find((project) => project.id === projectId);
  const [updateProject] = useUpdateProjectMutation();
  const {
    data: awardedResult,
    isSuccess,
    isError,
  } = useGetAwardedQuery(projectId);
  const [getInvoiceTrigger, invoicesResult] = useLazyGetInvoiceQuery();
  const [cancelInvoice] = useCancelInvoiceMutation();
  const [paymentSaved, setPaymentSaved] = useState(true);
  const [trigger] = useLazyGetInvoiceQuery();
  useListenForKeyboardPress('Escape', () => {
    closeModal();
  });
  const [newInvoices, setNewInvoices] = useState(
    invoicesResult.data?.invoices.length === 0 ? [DEFAULT_INVOICE] : []
  );
  const [cancelPayment] = useCancelPaymentMutation({
    fixedCacheKey: 'invoices-and-payments',
  });
  const [createMultipleInvoices] = useCreateMultipleInvoicesMutation({
    fixedCacheKey: 'invoices-and-payments',
  });
  const [createAwarded] = useCreateAwardedMutation({
    fixedCacheKey: 'awarded',
  });

  const [isEditingTotalFee, setEditingTotalFee] = useState(false);
  const [totalFeeValue, setTotalFeeValue] = useState(project.fee);
  const [awardedValue, setAwardedValue] = useState(0);
  // const [awardedValueToAdd, setAwardedValueToAdd] = useState(0)
  const [isEditingAwarded, setEditingAwarded] = useState(false);

  if (invoicesResult.isError || isError) {
    console.log('Error on opening invoice modal, resetting...');
    closeModal();
  }

  const handleSaveTotalFee = async () => {
    setEditingTotalFee(false);
    try {
      await updateProject({
        ...project,
        fee: totalFeeValue,
      }).unwrap();
      await trigger(projectId.toString()).unwrap();
      toast.success(
        `Successfully updated total fee to ${currencyFormatter().format(totalFeeValue)}.`
      );
    } catch (e) {
      console.error('invoice and payments modal:: handleSaveTotalFee');
      console.error(JSON.stringify(e));
      toast.error(
        'Failed to update total fee. Please try again or contact customer support.'
      );
    }
  };

  const handleSaveAwarded = async () => {
    if (awardedValue > totalFeeValue || awardedValue < 0) {
      toast.error(
        'The total awarded value cannot be greater than the total fee. Please try another number.'
      );
      setAwardedValue(0);
      return;
    }

    try {
      await createAwarded({
        awarded_amount: awardedValue,
        projectId,
      }).unwrap();
      // This is a useless update to update the project values of awarded on the table...
      await updateProject({
        ...project,
      }).unwrap();
      setEditingAwarded(false);
      toast.success(
        `Total awarded was updated to ${currencyFormatter().format(awardedValue)}.`
      );
    } catch (err) {
      console.error(err);
      toast.error(
        'Failed to update total awarded value. Please try again or contact customer support.'
      );
    }
  };

  // This is so that it gets the latest data. There seemed to be a race condition where simply triggering re-render didn't get the latest payment data for the invoices
  // But not the most optimal because upon re-render, useGetInvoiceQuery is triggered, so we're calling the get request twice...
  useEffect(() => {
    if (paymentSaved) {
      // TODO: 2 Network calls for every payment save...not sure why
      getInvoiceTrigger(projectId.toString());
      setPaymentSaved(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSaved]);

  // This is when we had separate awarded records to sum up to the total
  const totalAwarded = Number(awardedResult?.awarded?.awarded_amount ?? 0);

  useEffect(() => {
    if (isSuccess) {
      setAwardedValue(totalAwarded);
    }
  }, [isSuccess, setAwardedValue, totalAwarded]);

  if (!invoicesResult.data) {
    return null;
  }

  const allInvoices = invoicesResult.data.invoices.map(
    (invoiceData) => invoiceData.invoice
  );
  const totalInvoicedFee = totalInvoiceFee(allInvoices, allInvoices.length - 1);
  const isFullyInvoiced = totalInvoicedFee >= totalFeeValue;
  const totalPaid = totalPaymentAmount(invoicesResult.data.invoices);

  return (
    <Modal
      title="Invoices and Payments"
      closeModal={closeModal}
      CloseIcon={() => <Close closeModal={closeModal} />}
    >
      <div className="lg:w-[100%] xl:w-[1280px]">
        <table width="100%" role="table" className="mb-8">
          <tr className="flex-table header flex" role="rowgroup">
            <th
              className="border border-black border-r-0 w-1/4 flex-row flex justify-between px-2"
              role="columnheader"
            >
              {isEditingTotalFee ? (
                <div className="flex justify-between w-full">
                  <div className="flex items-center text-base">
                    $
                    <input
                      type="number"
                      className="text-base block px-2 py-1 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      value={totalFeeValue}
                      onChange={(e) => {
                        setTotalFeeValue(parseFloat(e.target.value));
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-base"
                    onClick={handleSaveTotalFee}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <>
                  <h3 className="text-gray-800 text-lg font-bold">
                    Total Fee: {currencyFormatter().format(totalFeeValue)}
                  </h3>
                  <button
                    type="button"
                    className="text-base"
                    onClick={() => {
                      setEditingTotalFee(true);
                    }}
                  >
                    Edit
                  </button>
                </>
              )}
            </th>
            <th
              className="border border-black border-r-0 w-1/4 flex-row flex justify-between px-2"
              role="columnheader"
            >
              {isEditingAwarded ? (
                <div className="flex flex-col justify-between w-full">
                  <div>
                    <span className="text-base">
                      Currently awarded: $ {awardedValue}
                    </span>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex items-center text-base">
                      $
                      <input
                        type="number"
                        className="block px-2 py-1 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                        value={awardedValue}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setAwardedValue(value);
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="text-base"
                      onClick={handleSaveAwarded}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h3 className="text-gray-800 text-lg font-bold">
                    Awarded: {currencyFormatter().format(totalAwarded ?? 0)}
                  </h3>
                  <button
                    type="button"
                    className="text-base"
                    onClick={() => {
                      setEditingAwarded(true);
                    }}
                  >
                    Edit
                  </button>
                </>
              )}
            </th>
            <th
              className="border border-black border-r-0 w-1/4 flex-row flex justify-between px-2"
              role="columnheader"
            >
              <h3 className="text-gray-800 text-lg font-bold">
                Invoiced: {currencyFormatter().format(totalInvoicedFee)}
              </h3>
            </th>
            <th
              className="border border-black w-1/4 flex-row flex justify-between px-2"
              role="columnheader"
            >
              <h3 className="text-gray-800 text-lg font-bold">
                Total Paid: {currencyFormatter().format(totalPaid)}
              </h3>
            </th>
          </tr>
        </table>
        <table
          className="invoice-and-payments-modal mx-auto box-border relative"
          width="100%"
          role="table"
          aria-label="Invoice and Payments"
        >
          <thead>
            <tr className="flex-table header" role="rowgroup">
              <th className="flex-row text-base first" role="columnheader">
                Invoiced Date (YYYY-MM-DD)
              </th>
              <th className="flex-row text-base" role="columnheader">
                Invoiced Fee (% of awarded)
              </th>
              <th className="flex-row text-base" role="columnheader">
                Invoiced to date (% of awarded)
              </th>
              <th className="flex-row text-base" role="columnheader">
                Payment Received
              </th>
              {/* <th className="flex-row text-base" role="columnheader"></th> */}
            </tr>
          </thead>
          <tbody>
            {invoicesResult.data.invoices.map((invoiceData, idx) => {
              return (
                <TableBody
                  invoice={invoiceData.invoice}
                  payment={invoiceData.payments[0] ?? undefined}
                  totalInvoicedFee={totalInvoiceFee(allInvoices, idx)}
                  awardedValue={awardedValue}
                  triggerPaymentCancelled={async (cancelPaymentPayload) => {
                    try {
                      await cancelPayment(cancelPaymentPayload).unwrap();
                      setPaymentSaved(true);
                      toast.success('Successfully saved payment information.');
                    } catch (err) {
                      console.error(err);
                      toast.error(
                        'Failed to uncheck received for this invoice. Please try again or contact customer support.'
                      );
                    }
                  }}
                  triggerPaymentSaved={async () => {
                    try {
                      await trigger(projectId.toString()).unwrap();
                      setPaymentSaved(true);
                      toast.success('Successfully saved payment information.');
                    } catch (err) {
                      console.error(err);
                      toast.error(
                        'Failed to save invoice information. Please try again or contact customer support.'
                      );
                    }
                  }}
                  cancelInvoice={async (invoiceId) => {
                    try {
                      await cancelInvoice(invoiceId).unwrap();
                      toast.success('Successfully deleted invoice.');
                    } catch (err) {
                      console.error(err);
                      toast.error(
                        'Failed to delete invoice. Please try again or contact customer support.'
                      );
                    }
                  }}
                />
              );
            })}
            {!isFullyInvoiced ? (
              <>
                {newInvoices.map((invoiceData, idx) => {
                  return (
                    <tr className="flex-table row" role="rowgroup" key={idx}>
                      <td
                        className="text-gray-700 flex-row invoice-and-payments-datepicker relative"
                        role="cell"
                      >
                        <DatePicker
                          selected={invoiceData.invoiced_date}
                          onChange={(date) => {
                            const unixTimestamp = new Date(date).getTime();
                            const newInvoiceData = {
                              ...invoiceData,
                              invoiced_date: unixTimestamp,
                            };
                            const newInvoicesToUpdate = [...newInvoices];
                            newInvoicesToUpdate[idx] = newInvoiceData;

                            setNewInvoices(newInvoicesToUpdate);
                          }}
                        />
                      </td>
                      <td
                        className="text-gray-700 text-base flex-row"
                        role="cell"
                      >
                        <input
                          className="w-[55%] p-[4px]"
                          type="number"
                          value={invoiceData.invoice_amount}
                          onChange={(e) => {
                            const newInvoiceAmount = parseFloat(e.target.value);
                            const newInvoiceData = {
                              ...invoiceData,
                              invoice_amount: newInvoiceAmount,
                            };
                            const newInvoicesToUpdate = [...newInvoices];
                            newInvoicesToUpdate[idx] = newInvoiceData;

                            setNewInvoices(newInvoicesToUpdate);
                          }}
                        />
                      </td>
                      {/* <td className="text-gray-700 text-base flex-row" role="cell">
                      {isNaN(totalInvoicedFee + newInvoiceAmount) ? 0 : totalInvoicedFee + newInvoiceAmount} ({((totalInvoicedFee + newInvoiceAmount) / totalFee * 100).toFixed(2)}%)
                    </td> */}
                      <td
                        className="text-gray-700 text-base flex-row"
                        role="cell"
                      >
                        N/A
                      </td>
                      <td
                        className="text-gray-700 text-base flex-row"
                        role="cell"
                      >
                        N/A
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : null}

            {!isFullyInvoiced ? (
              <tr className="flex-table row w-full" role="rowgroup">
                <td className="flex-row relative" role="cell">
                  <button
                    type="button"
                    className="flex items-center"
                    onClick={() => {
                      setNewInvoices([...newInvoices, DEFAULT_INVOICE]);
                    }}
                  >
                    <svg
                      className="h-8 w-8 text-blue-600"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="9" y1="12" x2="15" y2="12" />
                      <line x1="12" y1="9" x2="12" y2="15" />
                    </svg>
                    <span className="text-base">Add new invoice row</span>
                  </button>
                </td>
                {newInvoices.length > 0 && (
                  <td className="text-base flex-row relative" role="cell">
                    <button
                      type="button"
                      className="flex items-center"
                      onClick={() => {
                        if (newInvoices.length === 0) {
                          alert('No new invoice row to delete');
                          return;
                        }
                        setNewInvoices(newInvoices.slice(0, -1));
                      }}
                    >
                      <svg
                        className="h-8 w-8 text-red-500"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="12" cy="12" r="9" />
                        <line x1="9" y1="12" x2="15" y2="12" />
                      </svg>
                      Remove last invoice row
                    </button>
                  </td>
                )}
              </tr>
            ) : null}
          </tbody>
        </table>

        {isFullyInvoiced && (
          <div className="mt-6 text-teal-500 text-center">Fully Invoiced</div>
        )}
        {!isFullyInvoiced && newInvoices.length > 0 && (
          <div className="flex">
            <button
              className="ml-auto w-[300px] px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-emerald-800 rounded-md sm:mt-0 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
              type="button"
              onClick={async () => {
                // When dividing unixTimestamp by 1000, the caledar library doesn't recognize it properly (?) and shows Jan 20th, 1970
                const adjustedDateInvoices = newInvoices.map((invoice) => ({
                  ...invoice,
                  invoiced_date: invoice.invoiced_date / 1000,
                }));

                try {
                  await createMultipleInvoices({
                    project_id: projectId,
                    invoices: adjustedDateInvoices,
                  }).unwrap();
                  setNewInvoices([]);
                  toast.success('Successfully updated invoices.');
                } catch (err) {
                  console.error(err);
                  toast.error(
                    'Failed to update invoice(s). Please try again or contact customer support.'
                  );
                }
              }}
            >
              Save invoice(s)
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
