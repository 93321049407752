import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type Login as LoginType } from 'state/types';
import { useAuth } from '../../hooks';
import { selectIsLoggedIn } from '../../state/services';
import { emailValidator } from '../../utils';
import { useGetNavigation } from '../../utils/hooks';
import { toast } from 'react-toastify';

const initUserInfo = {
  email: '',
  password: '',
};

export const Login = () => {
  const [userInfo, setUserInfo] = useState<LoginType>(initUserInfo);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { handleLoginAll } = useAuth();
  const { navigate } = useGetNavigation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('demo') === '2025') {
      toast.info('Demo login credentials loaded.');
      setTimeout(() => {
        toast.info('Simply click login to proceed.');
      }, 1000);
      setUserInfo({
        email: 'demo@firm.com',
        password: 'Demo2025#',
      });
    }

    if (queryParams.get('demo') === '2025') {
      // Clear all cookies
      document.cookie.split(';').forEach((cookie) => {
        document.cookie = cookie
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });

      // Clear local storage
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/projects');
    }
  }, [isLoggedIn, navigate]);

  // TODO: login business logic here
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!emailValidator(userInfo.email)) {
      return toast.error(
        'Not a valid email address, please check and try again.'
      );
    }

    if (
      userInfo.email.length > 0 &&
      userInfo.password.length > 0 &&
      emailValidator(userInfo.email)
    ) {
      const body = { email: userInfo.email, password: userInfo.password };

      try {
        await handleLoginAll(body);
      } catch (err) {
        toast.error(
          'Login failed. Please try again or contact customer support.'
        );
        return;
      }
      return;
    }
    // Log sentry error here
    toast.error('Something went wrong, please contact customer support.');
  };

  const handleClear = () => {
    setUserInfo(initUserInfo);
  };

  // TODO: Handle FE validation
  return (
    <form className="w-[300px] m-auto" onSubmit={handleLogin}>
      <div>
        <h1
          className="text-lg font-large leading-6 text-gray-800 capitalize "
          id="modal-title"
        >
          Login
        </h1>
      </div>
      <div>
        <label className="text-sm text-gray-700" htmlFor="email">
          <input
            value={userInfo.email}
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              });
            }}
            onBlur={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              });
            }}
            type="email"
            name="email"
            id="email"
            placeholder="email"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600"
          />
        </label>
        {new URLSearchParams(window.location.search).get('demo') ===
        '2025' ? null : (
          <label className="text-sm text-gray-700" htmlFor="password">
            <input
              value={userInfo.password}
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                });
              }}
              onBlur={(e) => {
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                });
              }}
              type="password"
              name="password"
              id="password"
              placeholder="password"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600"
            />
          </label>
        )}
      </div>
      <div className="mt-4 sm:flex sm:items-center sm:-mx-2 flex flex-col">
        <div className="w-full flex">
          <button
            type="submit"
            onClick={handleLogin}
            className="w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          >
            Login
          </button>
          <button
            type="reset"
            className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
        {/* <a href="/create-firm" className="text-sm font-medium w-full">
            Create Firm
          </a> */}
      </div>
    </form>
  );
};
