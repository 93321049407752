import React, { useState } from 'react';
import {
  BriefcaseBusiness,
  Menu,
  X,
  LogIn,
  LogOut,
  User,
  type LucideProps,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectFirm } from '../../state/services';
import { useGetNavigation } from '../../utils/hooks';
import { LampsideLogoLight, LampsideLogoDark } from '../../components/icons';
import { useAuth } from '../../hooks';
import {
  ALLOWED_ROLES,
  selectIsLoggedIn,
  selectUserType,
} from '../../state/services';

export const NavBar = () => {
  const { navigate } = useGetNavigation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userType = useSelector(selectUserType);
  const IS_EDITOR = !ALLOWED_ROLES.includes(userType);
  const [isOpen, setIsOpen] = useState(false);
  const firm = useSelector(selectFirm);

  const navigation: {
    isAuth?: boolean;
    title: string;
    path: string;
    icon: React.ForwardRefExoticComponent<
      Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
    >;
  }[] = [];
  if (isLoggedIn) {
    if (!IS_EDITOR) {
      navigation.push({
        title: 'Firm',
        path: '/firm',
        icon: BriefcaseBusiness,
      });
    }
    navigation.push({ title: 'User', path: '/user', icon: User });
  }

  navigation.push({
    isAuth: true,
    title: isLoggedIn ? 'Logout' : 'Login',
    path: isLoggedIn ? '/logout' : '/login',
    icon: isLoggedIn ? LogOut : LogIn,
  });

  const submenuNav = [
    IS_EDITOR ? {} : { title: 'Dashboard', path: '/dashboard' },
    { title: 'Projects', path: '/projects' },
    { title: 'Accounts', path: '/accounts' },
  ];

  return (
    <header className="text-base fixed top-0 z-30 w-full relative">
      <nav className="bg-[#01382D] shadow-sm px-4 py-2 flex justify-between items-center">
        <button
          className="btn btn-square btn-ghost md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        <div className="flex gap-2 items-center justify-between w-auto">
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            <LampsideLogoLight />
          </button>
          {/* 
            <h2 className="text-xs text-left m-0 p-0">
            Powered by Lampside<span className="">&#169;</span>
            </h2> */}
        </div>
        {firm.id !== 2 && firm.id !== undefined ? (
          <div className="text-xl font-semibold text-white font-poppins whitespace-nowrap mx-auto absolute left-1/2 transform -translate-x-1/2">
            THIS IS A DEMO VERSION.{' '}
            <a
              href="mailto:lampsidesoftware@gmail.com?subject=Request for access&body=Hi, I would like to create a login for my firm."
              className="underline decoration-white"
            >
              CLICK HERE TO GET STARTED WITH LAMPSIDE!
            </a>
          </div>
        ) : null}
        <h1 className="text-xl font-semibold text-white font-poppins">
          {firm.name}
        </h1>

        {/* <ul
          className={`md:flex space-x-4 ${isOpen ? 'block' : 'hidden'} md:block absolute md:static top-16 left-0 w-full md:w-auto p-4 md:p-0`}
        >
          {navigation.map((item, idx) => {
            return (
              <li key={idx} className="">
                <button
                  className="flex gap-1 cursor-pointer text-gray-500 hover:text-gray-900"
                  onClick={() => {
                    if (item.isAuth) {
                      if (item.path === '/login') {
                        navigate(item.path);
                        return;
                      }
                      handleLogoutAll();
                      return;
                    }
                    navigate(item.path);
                    return;
                  }}
                >
                  {<item.icon className="" />}
                  {item.title}
                </button>
              </li>
            );
          })}
        </ul> */}
      </nav>
      <div>
        {/* {isLoggedIn ? (
          <nav className="border-b bg-white">
            <ul className="flex items-center gap-x-3 px-6">
              {submenuNav.map((item, idx) => {
                return (
                  <li
                    key={idx}
                    className={`py-1 ${location.pathname === item.path ? 'border-b-2 border-blue-600' : ''}`}
                  >
                    <button
                      className="text-sm block py-2 px-3 rounded-lg text-gray-700 hover:text-gray-900 hover:bg-gray-100 duration-150"
                      onClick={() => {
                        navigate(item.path);
                      }}
                    >
                      {item.title}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav>
        ) : null} */}
      </div>
    </header>
  );
};
