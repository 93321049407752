import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  useGetAllProjectsQuery,
  useGetFinancialDataQuery,
} from '../../state/services';
import type { QuarterAwardData } from '../../state/services';
import { Chart } from './chart';
import { PieChart } from './pie-chart';
import { PaymentsPerDay } from './payments-per-day';
import { AverageDaysToPayment } from './avg-days-to-payment';
import { AwardedAmountPerQuarter } from './awarded-amount-per-quarter';
import { AwardedContributions } from './awarded-contributions';
import { InactiveProjects } from './inactive-projects';
import { PendingProjectsPerAccount } from './pending-projects-per-account';
import { Loader } from '../../components/loader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const TEMP_2024_QUARTERS = [1, 2, 3];

export const Dashboard = () => {
  const queryProjectsData = useGetAllProjectsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const firmId = queryProjectsData.isSuccess
    ? queryProjectsData.data.user.firm_id
    : undefined;
  const queryFinancialData = useGetFinancialDataQuery(firmId, {
    skip: !queryProjectsData.isSuccess,
    refetchOnMountOrArgChange: true,
  });

  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [selectedRow, setSelectedRow] = useState<QuarterAwardData | null>(null);
  const [tableData, setTableData] = useState<QuarterAwardData[]>([]);

  useEffect(() => {
    if (queryFinancialData.isSuccess && tableData.length === 0) {
      setTableData(
        Object.values(queryFinancialData.data.financial_data.quarter_totals)
      );
    }
  }, [queryFinancialData, tableData.length]);

  if (!queryFinancialData.isSuccess) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col px-[44px]">
      <div>
        <h1 className="text-3xl font-bold">Dashboard</h1>
      </div>
      <div className="flex w-full px-12 justify-center">
        <AwardedContributions data={queryProjectsData.data.projects} />
      </div>
      <div className="p-4 grid grid-cols-2 gap-6">
        <div className="flex p-2">
          <AverageDaysToPayment
            data={queryFinancialData.data.average_days_to_payment}
          />
        </div>
        <div className="flex">
          <AwardedAmountPerQuarter
            data={queryFinancialData.data.financial_data}
          />
        </div>
        <div className="flex">
          <PaymentsPerDay data={queryFinancialData.data.payments_per_day} />
        </div>
        <div className="flex">
          <InactiveProjects data={queryProjectsData.data.projects} />
        </div>
      </div>
      <div className="flex w-full px-12 justify-center">
        <PendingProjectsPerAccount data={queryProjectsData.data.projects} />
      </div>
    </div>
  );
};
