import { FormattedProject, Project, ProjectStatus } from 'state/types';

export type Address = {
  unit: string;
  street_address: string;
  city: string;
  province: string;
  postal_code: string;
  country: string;
};

export const numberizefee = (projects: Project[]) => {
  return projects.map((project) => {
    return {
      ...project,
      // Decimal types from Backend come back as strings in the JSON response
      fee: parseFloat(project.fee as unknown as string),
    };
  });
};

export const numberizeFeeAndAwarded = (projects: Project[]) => {
  return projects.map((project) => {
    return {
      ...project,
      // Decimal types from Backend come back as strings in the JSON response
      fee: parseFloat(project.fee as unknown as string),
      awarded: parseFloat(project.awarded as string),
    };
  });
};

export const statusizeProjects = (projects: Project[]) => {
  const statusizedProjects: ProjectStatus = {
    // TODO: Update these keys to these
    ALL: [],
    ACTIVE: [],
    'PENDING PROPOSAL': [],
    'PROPOSAL SENT': [],
    COMPLETE: [],
    LOST: [],
  };

  projects.forEach((project) => {
    statusizedProjects[project.status.toUpperCase()].push(project);
    statusizedProjects['ALL'].push(project);
  });

  return {
    statusizedProjects,
    statuses: Object.keys(statusizedProjects) as Project['status'][],
  };
};

const PROVINCE_MAP = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT',
};

const COUNTRY_MAP = {
  Canada: 'CAN',
  'United States': 'USA',
};

export const formatToSiteAddress = (address: Address) => {
  const { unit, street_address, city, province, country, postal_code } =
    address;
  const provinceAbbrev = PROVINCE_MAP[province] ?? '';
  const countryAbbrev = COUNTRY_MAP[country] ?? '';
  const site_address = [
    unit ? `${unit} ` : '',
    street_address ? `${street_address}.` : '',
    city ? `${street_address ? ' ' : ''}${city}` : '',
    provinceAbbrev
      ? `${city ? ', ' : street_address ? ' ' : ''}${provinceAbbrev}`
      : '',
    countryAbbrev
      ? `${provinceAbbrev ? ', ' : city || street_address ? ' ' : ''}${countryAbbrev}`
      : '',
    postal_code
      ? `${countryAbbrev ? '. ' : city || street_address || provinceAbbrev ? ' ' : ''}${postal_code}`
      : '',
  ].join('');

  return { site_address };
};

export const prepProjectsForTable = (
  projects: Project[]
): FormattedProject[] => {
  return projects.map((project) => {
    const { unit, street_address, city, province, country, postal_code } =
      project;
    const { site_address } = formatToSiteAddress({
      unit,
      street_address,
      city,
      province,
      country,
      postal_code,
    });

    return {
      ...project,
      site_address,
      // fee: parseFloat(project.fee as unknown as string),
    };
  });
};
