import React, { useState } from 'react';

import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from 'react-country-state-city';
import { toast } from 'react-toastify';

import 'react-country-state-city/dist/react-country-state-city.css';

import { Project } from 'state/types';
import { PostalCodeInput } from '../../components/location';
import { generateFiscalYears } from '../../pages/projects/utils';
import { Modal } from '../../shared';
import { PROJECT_STATUS_OPTIONS } from '../../state/global';
import {
  useAddProjectMutation,
  useGetAllAccountsQuery,
} from '../../state/services';
import { capitalizeWords, sortAlphabetically } from '../../utils';
import { AddAccountModal } from '../account/add-account-modal';

type Props = {
  unique_project_types: string[];
  closeModal: (refreshData?: boolean) => void;
};

export const AddProjectModal = ({
  closeModal,
  unique_project_types,
}: Props) => {
  const [countryid, setCountryid] = useState(39);
  const [stateid, setstateid] = useState(875);
  const [cityid, setCityid] = useState(0);
  const [projectData, setProjectData] = useState<Project | null>({
    country: 'Canada',
    province: 'British Columbia',
  } as Project);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  // TODO: Handle Unique Project types better
  const [projectTypes, setProjectTypes] = useState(
    sortAlphabetically(unique_project_types) as string[]
  );
  const [isAddingProjectType, setIsAddingProjectType] = useState(false);
  const [projectTypeToAdd, setProjectTypeToAdd] = useState('');
  const [addProject] = useAddProjectMutation({});
  const allAccounts = useGetAllAccountsQuery('add-project-modal');

  // TODO: Better logic here
  const isProjectFilled = (): boolean => {
    let value = false;

    if (!projectData) {
      return false;
    }

    if (
      projectData.account_id &&
      projectData.site &&
      projectData.project_type &&
      projectData.status &&
      projectData.account_id
    ) {
      return true;
    }

    return value;
  };

  const addProjectHandler = async () => {
    try {
      const newProject = {
        site: projectData.site,
        unit: projectData.unit,
        street_address: projectData.street_address,
        city: projectData.city,
        province: projectData.province,
        country: projectData.country,
        postal_code: projectData.postal_code,
        project_type: projectData.project_type.toLowerCase(),
        status: projectData.status ?? 'PENDING PROPOSAL',
        fee: projectData.fee ?? 0,
        // invoiced_fee: projectData.invoiced_fee,
        account_id: projectData.account_id,
        comments: projectData.comments,
        fiscal_year: projectData.fiscal_year,
      };

      await addProject(newProject).unwrap();
      const account_name = allAccounts.data?.find(
        (account) => account.id === projectData.account_id
      )?.name;
      toast.success(
        `Project for site "${newProject.site}" for account "${account_name}" has been added successfully!`
      );
    } catch (err) {
      toast.error(
        `Error adding "${projectData.site}" project. Please try again or contact customer support.`
      );
      console.error(`add-project-modal.tsx:addProjectHandler:: ${err.error}`);
      throw new Error('See addProjectHandler errors');
    }
  };

  const handleAccountChange = (e) => {
    const numberizedValue = Number(e.target.value);
    if (isNaN(numberizedValue)) {
      toast.error(
        'Error selecting account. Please try again or contact customer support.'
      );
      return;
    }
    setProjectData({
      ...projectData,
      account_id: numberizedValue,
    });
  };

  const handleAddNewAccount = () => {
    setIsAddingAccount(true);
  };

  const addNewUniqueProjectType = () => {
    setIsAddingProjectType(true);
  };

  const cancelAddNewUniqueProjectType = () => {
    // setting value as '' resets to disabled placeholder for the select dropdown option
    setProjectData({
      ...projectData,
      project_type: '',
    });
    setIsAddingProjectType(false);
  };

  const blurAddProjectType = (e) => {
    setProjectTypeToAdd(e.target.value);
  };

  const handleAddNewProjectType = () => {
    if (projectTypeToAdd === '') {
      toast.error('Please enter new Project Type.');
      return;
    }
    setProjectTypes([...projectTypes, projectTypeToAdd]);
    setProjectData({
      ...projectData,
      project_type: projectTypeToAdd,
    });
    setIsAddingProjectType(false);
  };

  return (
    <Modal
      closeModal={closeModal}
      title={`Add a new ${isAddingAccount ? 'Account' : 'Project'}`}
    >
      <>
        <form className="sm:w-[200px] md:w-[375px] lg:w-[500px]" action="#">
          {isAddingAccount ? (
            <AddAccountModal setIsAddingAccount={setIsAddingAccount} />
          ) : (
            <div>
              {allAccounts.data?.length > 0 ? (
                <>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-site"
                  >
                    <input
                      required
                      disabled={isAddingProjectType}
                      onBlur={(e) => {
                        setProjectData({
                          ...projectData,
                          site: e.target.value,
                        });
                      }}
                      type="text"
                      name="project-site"
                      id="project-site"
                      placeholder="Project building*"
                      className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                    />
                  </label>
                  <label className="text-sm text-gray-700" htmlFor="fee">
                    <input
                      disabled={isAddingProjectType}
                      onBlur={(e) => {
                        const updatedFee = (
                          e.target.value.length === 0 || e.target.value === null
                            ? 0
                            : e.target.value
                        ).toString();
                        setProjectData({
                          ...projectData,
                          fee: parseFloat(updatedFee),
                        });
                      }}
                      type="number"
                      name="fee"
                      id="fee"
                      placeholder="Fees"
                      className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                    />
                  </label>
                  {
                    <div
                      className={
                        isAddingProjectType
                          ? 'shadow-xl  border-solid border-2 rounded-lg bg-slate-200'
                          : ''
                      }
                    >
                      <label
                        className="text-sm text-gray-700"
                        htmlFor="project-type"
                      >
                        <select
                          className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                          value={projectData?.project_type ?? undefined}
                          onChange={(e) => {
                            if (e.target.value === 'add') {
                              addNewUniqueProjectType();
                              return;
                            }
                            setIsAddingProjectType(false);
                            setProjectData({
                              ...projectData,
                              project_type: e.target.value,
                            });
                          }}
                        >
                          <option disabled selected value="">
                            Select a project type*
                          </option>
                          {projectTypes.map((project_type) => {
                            return (
                              <option value={project_type}>
                                {capitalizeWords(project_type)}
                              </option>
                            );
                          })}
                          <option value="add">
                            &lt;&lt; Add new project type &gt;&gt;
                          </option>
                        </select>
                      </label>
                      {isAddingProjectType ? (
                        <div className="mt-4">
                          <label
                            className="text-sm text-gray-700"
                            htmlFor="add-project-type"
                          >
                            <input
                              type="text"
                              name="add-project-type"
                              id="add-project-type"
                              placeholder="Add Project Type"
                              className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                              onBlur={blurAddProjectType}
                            />
                          </label>
                          <button
                            type="button"
                            className="w-full px-4 py-2 mt-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            onClick={handleAddNewProjectType}
                          >
                            Add new project type
                          </button>
                          <button
                            type="button"
                            className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                            onClick={cancelAddNewUniqueProjectType}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : null}
                    </div>
                  }
                  <label className="text-sm text-gray-700" htmlFor="comments">
                    <select
                      className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                      onChange={(e) => {
                        setProjectData({
                          ...projectData,
                          status: e.target.value as Project['status'],
                        });
                      }}
                    >
                      <option disabled selected value="">
                        Select a status*
                      </option>
                      {sortAlphabetically(PROJECT_STATUS_OPTIONS).map(
                        (statusOption) => {
                          return statusOption !== 'ALL' ? (
                            <option value={statusOption}>{statusOption}</option>
                          ) : null;
                        }
                      )}
                    </select>
                  </label>
                  {
                    <label
                      className="text-sm text-gray-700"
                      htmlFor="project-type"
                    >
                      <select
                        className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                        onChange={(e) => {
                          if (e.target.value === 'add') {
                            handleAddNewAccount();
                            return;
                          }
                          handleAccountChange(e);
                        }}
                      >
                        <option disabled selected value="">
                          Select an account*
                        </option>
                        <option value="add">
                          &lt;&lt; Add new account &gt;&gt;
                        </option>
                        {allAccounts.data
                          ? Object.values(allAccounts.data).map(
                              (accountData) => {
                                return (
                                  <option value={accountData.id}>
                                    {accountData.name}
                                  </option>
                                );
                              }
                            )
                          : null}
                      </select>
                    </label>
                  }
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-fiscal-year"
                  >
                    <select
                      className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                      onChange={(e) => {
                        setProjectData({
                          ...projectData,
                          fiscal_year: Number(e.target.value),
                        });
                      }}
                    >
                      <option disabled selected value="">
                        Select fiscal year*
                      </option>
                      {generateFiscalYears().map((fiscalYear) => {
                        return <option value={fiscalYear}>{fiscalYear}</option>;
                      })}
                    </select>
                  </label>
                  {/* <label className="text-sm text-gray-700" htmlFor="project-quarter">
                        <select
                          className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                          onChange={(e) => {
                            setProjectData({
                              ...projectData,
                              quarter: Number(e.target.value) as 1 | 2 | 3 | 4,
                            })
                          }}
                        >
                          <option disabled selected value="">Select quarter*</option>
                          {
                            generateQuarters().map((quarter) => {
                              return (
                                <option value={quarter}>{quarter}</option>
                              )
                            })
                          }
                        </select>
                      </label> */}
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-unit"
                  >
                    <input
                      disabled={isAddingProjectType}
                      onBlur={(e) => {
                        setProjectData({
                          ...projectData,
                          unit: e.target.value,
                        });
                      }}
                      type="text"
                      name="project-unit"
                      id="project-unit"
                      placeholder="Unit"
                      className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                    />
                  </label>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-street_address"
                  >
                    <input
                      disabled={isAddingProjectType}
                      onBlur={(e) => {
                        setProjectData({
                          ...projectData,
                          street_address: e.target.value,
                        });
                      }}
                      type="text"
                      name="project-street_address"
                      id="project-street_address"
                      placeholder="Street Address"
                      className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                    />
                  </label>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-country"
                  >
                    <div className="mt-4">
                      <CountrySelect
                        className="bg-white"
                        onChange={(e) => {
                          setProjectData({
                            ...projectData,
                            country: e.name,
                          });
                          setCountryid(e.id);
                        }}
                        placeHolder="Select Country"
                        defaultValue={{
                          id: 39,
                          name: 'Canada',
                          iso3: 'CAN',
                          iso2: 'CA',
                          numeric_code: '124',
                          phone_code: '1',
                          capital: 'Ottawa',
                          currency: 'CAD',
                          currency_name: 'Canadian dollar',
                          currency_symbol: '$',
                          tld: '.ca',
                          native: 'Canada',
                          region: 'Americas',
                          subregion: 'Northern America',
                          latitude: '60.00000000',
                          longitude: '-95.00000000',
                          emoji: '🇨🇦',
                        }}
                      />
                    </div>
                  </label>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-province"
                  >
                    <div className="mt-4">
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setProjectData({
                            ...projectData,
                            province: e.name,
                          });
                          setstateid(e.id);
                        }}
                        placeHolder="Select State"
                        defaultValue={{
                          id: 875,
                          name: 'British Columbia',
                          state_code: 'BC',
                        }}
                      />
                    </div>
                  </label>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-city"
                  >
                    <div className="mt-4">
                      <CitySelect
                        countryid={countryid}
                        stateid={stateid}
                        onChange={(e) => {
                          setProjectData({
                            ...projectData,
                            city: e.name,
                          });
                          setCityid(e.id);
                        }}
                        placeHolder="Select City"
                      />
                    </div>
                  </label>
                  <label
                    className="text-sm text-gray-700"
                    htmlFor="project-postal_code"
                  >
                    <PostalCodeInput
                      isCanada={projectData?.country === 'Canada'}
                      label="project-postal_code"
                      updatePostalCode={(postalCode) => {
                        setProjectData({
                          ...projectData,
                          postal_code: postalCode,
                        });
                      }}
                    />
                  </label>

                  <label className="text-sm text-gray-700" htmlFor="comments">
                    <textarea
                      rows={1}
                      disabled={isAddingProjectType}
                      onBlur={(e) => {
                        setProjectData({
                          ...projectData,
                          comments: e.target.value,
                        });
                      }}
                      name="comments"
                      id="comments"
                      placeholder="Initial comments here"
                      className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                    />
                  </label>
                </>
              ) : (
                <div>
                  <div className="text-gray-800 text-base font-semibold">
                    This is your first time creating a project. You must create
                    at least one account to associate a project to an account
                    first by clicking the dropdown below.
                  </div>
                  {
                    <label
                      className="text-sm text-gray-700"
                      htmlFor="project-type"
                    >
                      <select
                        className="mt-4 capitalize w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-blue-600 focus:ring-2"
                        onChange={(e) => {
                          if (e.target.value === 'add') {
                            handleAddNewAccount();
                            return;
                          }
                          handleAccountChange(e);
                        }}
                      >
                        <option disabled selected value="">
                          Select an account*
                        </option>
                        {allAccounts.data
                          ? Object.values(allAccounts.data).map(
                              (accountData) => {
                                return (
                                  <option value={accountData.id}>
                                    {accountData.name}
                                  </option>
                                );
                              }
                            )
                          : null}
                        <option value="add">
                          &lt;&lt; Add new account &gt;&gt;
                        </option>
                      </select>
                    </label>
                  }
                </div>
              )}

              <div className="mt-4 sm:flex sm:items-center sm:-mx-2">
                <button
                  onClick={async () => {
                    if (!isProjectFilled()) {
                      toast.error(
                        'Please fill in all the required fields before adding a project.'
                      );
                      return;
                    }
                    try {
                      await addProjectHandler();
                      closeModal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type="button"
                  className="w-full px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:w-1/2 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                >
                  Add Project
                </button>
                <button
                  type="button"
                  className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </form>
      </>
    </Modal>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
