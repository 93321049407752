import { ChartOptions } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Project } from 'state/types';

const getInactiveProjectsThatArePendingOrSent = (
  projects: Project[],
  date = Date.now()
) => {
  return projects.filter(
    (project) =>
      project.status !== 'LOST' &&
      date - new Date(project.updated_at).getTime() > 30 * 24 * 60 * 60 * 1000
  );
};

export const InactiveProjects = ({ data }: { data: Project[] }) => {
  const dateToCompare = Date.now();
  const filteredDataByDate = getInactiveProjectsThatArePendingOrSent(
    data,
    dateToCompare
  );

  const labels = filteredDataByDate.reduce(
    (acc: string[], project: Project) => {
      if (!acc.includes(project.site)) {
        acc.push(
          project.site.length > 8
            ? `${project.site.slice(0, 8)}...`
            : project.site
        );
      }
      return acc;
    },
    []
  );
  const datasets = [
    {
      label: 'Projects that have not been updated in 30+ days (excludes lost)',
      data: filteredDataByDate.map((project) =>
        Math.floor(
          (dateToCompare - new Date(project.updated_at).getTime()) /
            (1000 * 60 * 60 * 24)
        )
      ),
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ];

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => filteredDataByDate[context[0].dataIndex].site,
          label: (context) => `${context.raw} days`,
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  const config = {
    labels,
    datasets,
  };

  return (
    <div className="h-[500px] w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
