import { useState } from 'react';
import { ProjectStatusValues } from 'state/types';

export const useProjectStatus = () => {
  const [status, setStatus] = useState<ProjectStatusValues>('ALL');
  const updateStatus = (newStatus: ProjectStatusValues) => {
    setStatus(newStatus);
  };

  return {
    status,
    updateStatus,
  };
};
