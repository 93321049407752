import { ChartOptions } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Project } from 'state/types';

export const PendingProjectsPerAccount = ({ data }: { data: Project[] }) => {
  const putProjectsIntoAccount = data.reduce(
    (acc: Record<string, Project[]>, project: Project) => {
      if (
        project.status !== 'PENDING PROPOSAL' &&
        project.status !== 'PROPOSAL SENT'
      ) {
        return acc;
      }
      if (!acc[project.account_name]) {
        acc[project.account_name] = [];
      }
      acc[project.account_name].push(project);
      return acc;
    },
    {}
  );
  const dataset = Object.values(putProjectsIntoAccount);
  const datasets = [
    {
      label: 'Proposal Pending or Proposal Sent Projects Per Account',
      data: dataset.map((projects) => projects.length),
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ];

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => dataset[context[0].dataIndex][0].account_name,
          label: (context) =>
            `${dataset[context.dataIndex].reduce((acc, project) => `${acc === '' ? '' : `${acc}, `}${project.site}`, '')}`,
        },
      },
    },
  };

  const config = {
    labels: Object.keys(putProjectsIntoAccount),
    datasets,
  };

  return (
    <div className="h-auto w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
