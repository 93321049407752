import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'tw-elements-react/dist/css/tw-elements-react.min.css';
import { App } from './App';
import './App.css';
import { Account } from './components/account';
import './index.css';
import { Accounts } from './pages/accounts';
import { CreateFirm } from './pages/create-firm';
import { CsvUploadPage } from './pages/csv-upload';
import { Dashboard } from './pages/dashboard';
import { Firm } from './pages/firm';
import { Invite } from './pages/invite';
import { Login } from './pages/login';
import { Project } from './pages/project';
// import { Projects } from './pages/projects';
import { ProjectsTest } from './pages/projects/projects-test';
import { Sites } from './pages/sites';
import { User } from './pages/user';
import { persistor, store } from './state/store';
import { ProtectedRoute } from './components/protected-route';
import { NavBar, Sidebar, NavContextProvider } from './components/navbar';

const Layout = () => {
  return (
    <NavContextProvider>
      <NavBar />
      <Sidebar />
      <div className="pt-[20px] pl-[44px]">
        <Outlet />
      </div>
    </NavContextProvider>
  );
};

const router = createBrowserRouter([
  // TODO: Update redirect to dashboard

  {
    path: '/',

    element: <Layout />,
    children: [
      {
        path: '/projects',
        element: <ProjectsTest />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/firm',
        element: (
          <ProtectedRoute>
            <Firm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/user',
        element: <User />,
      },
    ],
  },
  // TODO: USER CAN'T GET TO THIS YET, MAYBE RM
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/sites/:site',
        element: <Sites />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/accounts',
        element: <Accounts />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/account',
        element: <Account />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/project/:projectId',
        element: <Project />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/create-firm',
        element: <CreateFirm />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/csv-upload-test',
        element: (
          <ProtectedRoute>
            <CsvUploadPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    // element: <Invite />,
    children: [
      {
        path: '/invitations/accept/:token/:firmName/:inviter',
        element: (
          <ProtectedRoute>
            <Invite />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App>
          <RouterProvider router={router} />
        </App>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
