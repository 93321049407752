import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { currencyFormatter } from '../../utils';
import { Project } from 'state/types';

const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.2; // Adjust the alpha value as needed
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

type DataSet = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

export const AwardedContributions = ({ data }: { data: Project[] }) => {
  const labels = data.reduce((acc: string[], project: Project) => {
    if (!acc.includes(project.account_name)) {
      acc.push(project.account_name);
    }
    return acc;
  }, []);
  const dataset = data.reduce(
    (acc: DataSet, project: Project, index) => {
      acc.backgroundColor.push(getRandomColor());
      const matchingAccountNameIndex = labels.indexOf(project.account_name);
      acc.data[matchingAccountNameIndex] = acc.data[matchingAccountNameIndex]
        ? acc.data[matchingAccountNameIndex] +
          parseFloat(project.awarded as string)
        : parseFloat(project.awarded as string);
      return acc;
    },
    {
      label: 'Total Awarded',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }
  );
  const datasets = [
    {
      label: 'Awarded per account (Lifetime)',
      data: dataset.data,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ];

  const config = {
    labels,
    datasets,
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: number) {
            return currencyFormatter().format(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return currencyFormatter().format(context.raw);
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="h-auto w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
