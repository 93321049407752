import { ColumnFiltersState, VisibilityState } from '@tanstack/react-table'
import { useState } from 'react'

export const useTableStates = <T>() => {
  const [menuPosition, setMenuPosition] = useState<{x: number; y: number; } | null>(null)
  const [selectedRow, setSelectedRow] = useState<T | null>(null)
  const [editedRows, setEditedRows] = useState<Record<string, T>>({})
  const [deletedRows, setDeletedRows] = useState<Set<string>>(new Set())
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  return {
    menuPosition,
    setMenuPosition,
    selectedRow,
    setSelectedRow,
    editedRows,
    setEditedRows,
    deletedRows,
    setDeletedRows,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
  }
}