import React, { useState, useMemo } from 'react';
import { Column, Row, Table } from '@tanstack/react-table';
import { OptionType } from 'components/Select';
import { type MultiValue } from 'react-select';
import { Select } from '../../components/Select';

type EditSelectCellProps<TData> = {
  getValue: () => TData[keyof TData];
  row: Row<TData>;
  column: Column<TData>;
  table: Table<TData>;
  filterOptionsToId?: boolean;
  optionsOverride?: OptionType[];
  noOptionsMessage?: (msg: string) => string;
  stylesOverride?: string;
};

export const EditSelectCell = <TData,>({
  getValue,
  row,
  column,
  table,
  filterOptionsToId,
  optionsOverride,
  noOptionsMessage,
  stylesOverride,
}: EditSelectCellProps<TData>) => {
  const initialValue = useMemo(() => getValue(), [getValue]);
  const [selectedValue, setSelectedValue] = useState<TData[keyof TData] | null>(
    initialValue
  );
  const optionsKey = column.columnDef.meta?.optionsKey;
  const options = optionsOverride
    ? optionsOverride
    : optionsKey
      ? (table.options.meta?.optionsStore?.[optionsKey] ?? [])
      : [];

  let filteredOptions: OptionType[] = options;

  // TODO: Revisit this logic
  if (filterOptionsToId && options.length > 0) {
    filteredOptions = (options as unknown as (OptionType & { id: number })[])
      .filter((option) => option.id === Number(row.id))
      .map((option) => ({ value: option.value, label: option.label }));

    if (filteredOptions[0]?.label.includes('.')) {
      const optionToSplit = filteredOptions[0].label.split('.');
      filteredOptions = optionToSplit.map((option) => {
        const removedFirstCharWhitespace = option.startsWith(' ')
          ? option.slice(1)
          : option;
        return {
          value: removedFirstCharWhitespace,
          label: removedFirstCharWhitespace,
        };
      });
    }
  }

  const handleChange = (selectedOptions: MultiValue<OptionType>) => {
    const newValue =
      selectedOptions.length > 0
        ? (selectedOptions[0]?.value as TData[keyof TData])
        : null;
    setSelectedValue(newValue);
    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    table.options.meta!.updateData(
      row.id,
      column.id as keyof TData,
      newValue as TData[keyof TData]
    );
  };

  return (
    <Select
      value={
        selectedValue !== undefined
          ? filteredOptions.filter((option) => option.value === selectedValue)
          : []
      }
      options={filteredOptions}
      onChange={handleChange}
      isClearable
      noOptionsMessage={noOptionsMessage}
      stylesOverride={stylesOverride}
    />
  );
};
