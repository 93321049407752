import React, { useState } from 'react';

const validatePostalCode = (value, isCanada: boolean) => {
  // Canadian Postal Code Regex
  const canadianPostalCodeRegex = /^[A-Z]\d[A-Z]\d[A-Z]\d$/;
  // US Postal Code Regex
  const usPostalCodeRegex = /^\d{5}(-\d{4})?$/;

  return isCanada
    ? canadianPostalCodeRegex.test(value)
    : usPostalCodeRegex.test(value);
};

type Props = {
  initValue?: string;
  label: string;
  isCanada: boolean;
  updatePostalCode: (postalCode: string) => void;
  setError?: (errorMessage: string) => void;
};

// TODO: Update postalcodeinput consumers to show error in parent
export const PostalCodeInput = ({
  initValue,
  label,
  isCanada,
  updatePostalCode,
  setError,
}: Props) => {
  console.log('initValue', initValue);
  const [postalCode, setPostalCode] = useState(initValue ?? '');

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    setPostalCode(value);
  };

  const handleBlur = () => {
    setPostalCode(postalCode);
    setError?.('');
    updatePostalCode(postalCode);
    // Broken logic lol
    // if (validatePostalCode(postalCode, isCanada)) {
    // } else {
    //   console.log('invalid');
    //   setError?.('Invalid postal code format');
    // }
  };

  return (
    <div>
      <label htmlFor={label} className="text-sm text-gray-700">
        <input
          placeholder="Postal/Zip Code"
          className="mt-4 block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          id={label}
          type="text"
          value={postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={isCanada ? 6 : 10} // Adjust maxLength to fit the longer US format with hyphen
        />
      </label>
      {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
    </div>
  );
};
