import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Awarded, Project } from 'state/types';
import { getApiUrl } from '../../utils';

export type QuarterAwardData = {
  projects: { project: Project; award: Awarded }[];
  total_amount: number;
};

export type PaymentsPerDay = {
  payment_date: Date;
  payments_count: number;
  total_payment_amount: string;
  accounts: Record<
    string,
    Record<'projects', Record<'project_site' | 'payment_amount', string>[]>
  >;
};

export type AverageDaysToPayment = {
  account_id: number;
  account_name: string;
  avg_days_to_payment: string;
};

export type FinancialData = {
  quarter_totals: {
    Q1: QuarterAwardData;
    Q2: QuarterAwardData;
    Q3: QuarterAwardData;
    Q4: QuarterAwardData;
  };
  year_total: string;
};

const baseUrl = getApiUrl('api/dashboard/');

export const dashboard = createApi({
  reducerPath: 'dashboardApi',
  tagTypes: ['Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_X_API_KEY);

      return headers;
    },
    credentials: 'include',
    mode: 'cors',
  }),
  endpoints: ({ query }) => ({
    getFinancialData: query<
      {
        financial_data: FinancialData;
        payments_per_day: PaymentsPerDay[];
        average_days_to_payment: AverageDaysToPayment[];
      },
      number
    >({
      query: (firmId) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `/financial_data/${firmId}?timezone=${timezone}`;
      },
      providesTags: ['Dashboard'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetFinancialDataQuery } = dashboard;
export const dashboardApi = dashboard.endpoints;
