import React, { useEffect } from 'react';
import { useListenForKeyboardPress } from '../../utils/hooks';
import './modal.css';

interface CloseIconProps {
  onClick: (e) => void;
}

type Props = {
  title?: string;
  closeModal: () => void;
  CloseIcon?: React.FC<CloseIconProps>;
  modalHeight?: string;
  children: JSX.Element;
};

export const Modal = ({
  title,
  closeModal,
  CloseIcon,
  modalHeight,
  children,
}: Props) => {
  useListenForKeyboardPress('Escape', () => {
    closeModal();
  });
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <dialog className="modal modal-open">
      <div className={`bg-white rounded-md p-4`}>
        <div className="flex justify-between mb-4 ">
          <div className="bg-white">
            {title ? (
              <h3
                className="text-xl font-bold leading-6 text-gray-800 capitalize "
                id="modal-title"
              >
                {title}
              </h3>
            ) : null}
          </div>
          {CloseIcon ? <CloseIcon onClick={closeModal} /> : null}
        </div>
        {children}
      </div>
    </dialog>
  );
};
