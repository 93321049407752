import React from 'react';
import { currencyFormatter } from '../../utils';

const getCurrentFiscalYear = (fiscalStartMonth) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // JavaScript months are 0-indexed
  const currentYear = today.getFullYear();

  // If current month is before fiscal start month, we're in the previous fiscal year
  if (currentMonth < fiscalStartMonth) {
    return `FY${currentYear - 1}/${currentYear}`;
  } else {
    return `FY${currentYear}/${currentYear + 1}`;
  }
};

// Get current fiscal quarter based on firm's fiscal start month
const getCurrentFiscalQuarter = (fiscalStartMonth) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // JavaScript months are 0-indexed

  // Calculate how many months have passed since the fiscal year started
  let monthsIntoFiscalYear;
  if (currentMonth >= fiscalStartMonth) {
    monthsIntoFiscalYear = currentMonth - fiscalStartMonth;
  } else {
    monthsIntoFiscalYear = 12 - fiscalStartMonth + currentMonth;
  }

  // Determine quarter (0-2 months = Q1, 3-5 = Q2, 6-8 = Q3, 9-11 = Q4)
  const quarter = Math.floor(monthsIntoFiscalYear / 3) + 1;
  return `Q${quarter}`;
};

export const FinancialOverview = ({
  fiscal_start,
  total_quarter_awarded,
  total_year_awarded,
  total_fee,
}: {
  fiscal_start: number;
  total_quarter_awarded: string | number;
  total_year_awarded: string | number;
  total_fee: string | number;
}) => {
  const fiscalYear = getCurrentFiscalYear(fiscal_start);
  const fiscalQuarter = getCurrentFiscalQuarter(fiscal_start);
  return (
    <div className="bg-slate-50 border border-slate-200 shadow-sm rounded-lg p-4 mb-6">
      <h2 className="text-slate-700 font-medium text-sm mb-3">
        Financial Overview
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded border border-slate-200">
          <div className="flex items-center mb-1">
            <span className="text-slate-500 text-sm">Awarded QTD</span>
            <div className="ml-auto bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
              {fiscalQuarter}
            </div>
          </div>
          <div className="text-slate-900 text-xl font-bold">
            {currencyFormatter().format(total_quarter_awarded)}
          </div>
        </div>

        <div className="bg-white p-4 rounded border border-slate-200">
          <div className="flex items-center mb-1">
            <span className="text-slate-500 text-sm">Awarded YTD</span>
            <div className="ml-auto bg-green-100 text-green-800 text-xs font-medium px-2 py-0.5 rounded">
              {fiscalYear}
            </div>
          </div>
          <div className="text-slate-900 text-xl font-bold">
            {currencyFormatter().format(total_year_awarded)}
          </div>
        </div>

        <div className="bg-white p-4 rounded border border-slate-200">
          <div className="flex items-center mb-1">
            <span className="text-slate-500 text-sm">Total Fees</span>
          </div>
          <div className="text-slate-900 text-xl font-bold">
            {currencyFormatter().format(total_fee)}
          </div>
        </div>
      </div>
    </div>
  );
};
