import React, { useState } from 'react';
import { Account } from '../../../state/global';
import { useAddAccountMutation } from '../../../state/services';
import { PostalCodeInput } from '../../location';

import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from 'react-country-state-city';

import 'react-country-state-city/dist/react-country-state-city.css';
import { toast } from 'react-toastify';

type Props = {
  setIsAddingAccount: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddAccountModal = ({ setIsAddingAccount }: Props) => {
  const [accountData, setAccountData] = useState<Omit<Account, 'id'> | null>();
  const [addAccount] = useAddAccountMutation();
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [_cityid, setCityid] = useState(0);
  const isAccountFilled = (): boolean => {
    // Refactor this
    let value = false;
    if (!accountData) {
      return false;
    }
    if (accountData.name) {
      return true;
    }
    return value;
  };

  const addNewAccount = async () => {
    try {
      const newAccount = {
        name: accountData.name,
        poc: accountData.poc ?? 'N/A',
        unit: accountData.unit,
        street_address: accountData.street_address,
        city: accountData.city,
        province: accountData.province,
        country: accountData.country,
        postal_code: accountData.postal_code,
        email: accountData.email,
        phone: accountData.phone,
      };
      addAccount(newAccount);
      toast.success(`Successfully added ${accountData.name}!`);
    } catch (err) {
      console.error(`addNewAccount::error: ${JSON.stringify(err)}`);
      toast.error(
        `Failed to add account ${accountData.name}. Please try again or contact customer support.`
      );
    } finally {
      setIsAddingAccount(false);
    }
  };

  const cancelAddNewAccount = () => {
    setIsAddingAccount(false);
    setAccountData(null);
  };

  return (
    <div>
      <form className="mt=4" action="#">
        <div>
          <label className="text-sm text-gray-700" htmlFor="account-name">
            <input
              type="text"
              name="account-name"
              id="account-name"
              placeholder="Company Name*"
              className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
              onBlur={({ target: { value } }) => {
                setAccountData({
                  ...accountData,
                  name: value,
                });
              }}
            />
          </label>
          <label className="text-sm text-gray-700" htmlFor="account-poc">
            <input
              type="text"
              name="account-poc"
              id="account-poc"
              placeholder="Point of contact"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
              onBlur={({ target: { value } }) => {
                setAccountData({
                  ...accountData,
                  poc: value,
                });
              }}
            />
          </label>
          <label className="text-sm text-gray-700" htmlFor="account-phone">
            <input
              onBlur={(e) => {
                setAccountData({
                  ...accountData,
                  phone: e.target.value,
                });
              }}
              type="string"
              name="account-phone"
              id="account-phone"
              placeholder="Phone number here"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="text-sm text-gray-700" htmlFor="account-email">
            <input
              type="email"
              onBlur={(e) => {
                setAccountData({
                  ...accountData,
                  email: e.target.value,
                });
              }}
              name="account-email"
              id="account-email"
              placeholder="Email here"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="text-sm text-gray-700" htmlFor="account-unit">
            <input
              onBlur={(e) => {
                setAccountData({
                  ...accountData,
                  unit: e.target.value,
                });
              }}
              type="text"
              name="account-unit"
              id="account-unit"
              placeholder="Unit"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label
            className="text-sm text-gray-700"
            htmlFor="account-street_address"
          >
            <input
              onBlur={(e) => {
                setAccountData({
                  ...accountData,
                  street_address: e.target.value,
                });
              }}
              type="text"
              name="account-street_address"
              id="account-street_address"
              placeholder="Address"
              className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="text-sm text-gray-700" htmlFor="country">
            <div className="mt-4">
              <CountrySelect
                onChange={(e) => {
                  setAccountData({
                    ...accountData,
                    country: e.name,
                  });
                  setCountryid(e.id);
                }}
                placeHolder="Select Country"
              />
            </div>
          </label>
          <label className="text-sm text-gray-700" htmlFor="province">
            <div className="mt-4">
              <StateSelect
                countryid={countryid}
                onChange={(e) => {
                  setAccountData({
                    ...accountData,
                    province: e.name,
                  });
                  setstateid(e.id);
                }}
                placeHolder="Select State"
              />
            </div>
          </label>
          <label className="text-sm text-gray-700" htmlFor="city">
            <div className="mt-4">
              <CitySelect
                countryid={countryid}
                stateid={stateid}
                onChange={(e) => {
                  setAccountData({
                    ...accountData,
                    city: e.name,
                  });
                  setCityid(e.id);
                }}
                placeHolder="Select City"
              />
            </div>
          </label>
          <label className="text-sm text-gray-700" htmlFor="postal_code">
            <PostalCodeInput
              isCanada={accountData?.country === 'Canada'}
              label="project-postal_code"
              updatePostalCode={(postalCode) => {
                setAccountData({
                  ...accountData,
                  postal_code: postalCode,
                });
              }}
            />
          </label>

          <div className="mt-4 sm:flex sm:items-center sm:-mx-2">
            <button
              onClick={() => {
                if (!isAccountFilled()) {
                  alert('You must fill the required details, indicated by *');
                  return;
                }

                addNewAccount();
              }}
              type="button"
              className="w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            >
              Add Account
            </button>
            <button
              onClick={cancelAddNewAccount}
              type="button"
              className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
