import React from 'react'

type RowMenuProps = {
  x: number;
  y: number;
  openInvoiceModal: () => void
}

export const RowMenu = ({ x, y, openInvoiceModal }: RowMenuProps) => {
  return (
    <ul className={`menu rounded-box bg-white shadow-lg absolute z-10`} style={{
      top: `${y}px`,
      left: `${x}px`
    }}>
      <li>
        <button type="button" onClick={openInvoiceModal}>Open Invoices and fees</button>
      </li>
      <li>
        <button type="button" style={{ cursor: 'not-allowed' }}>More options coming soon...</button>
      </li>
    </ul>
  )
}