import React from 'react';
import { Bar } from 'react-chartjs-2';
import { type FinancialData as FinancialDataType } from 'state/services';

import { currencyFormatter } from '../../utils';
import { QuarterAwardData } from 'state/services';
import { ChartOptions } from 'chart.js';

export const AwardedAmountPerQuarter = ({
  data,
}: {
  data: FinancialDataType;
}) => {
  const labels = Object.keys(data.quarter_totals);
  const datasets = [
    {
      label: 'Awarded Amount Per Quarter',
      labels: labels.map(
        (label) => `Q${label.split('-')[1]} ${label.split('-')[0]}`
      ),
      data: Object.values(data.quarter_totals).map(
        (quarter: QuarterAwardData) => quarter.total_amount
      ),
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ];

  const config = {
    labels,
    datasets,
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: number) {
            return currencyFormatter().format(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return currencyFormatter().format(context.raw);
          },
        },
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'end',
        anchor: 'center',
        formatter: (value) => currencyFormatter().format(value),
      },
    },
  };

  return (
    <div className="h-auto w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
