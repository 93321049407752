import React, { useState } from 'react';

import { Firm, Fiscal_Start, Login, User } from 'state/types';
import { PostalCodeInput } from '../../components/location';
import { useUpdateDocumentTitle } from '../../hooks';
import { useGetNavigation } from '../../utils/hooks';
import { useCreateFirm } from './use-create-firm';

import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from 'react-country-state-city';

import 'react-country-state-city/dist/react-country-state-city.css';

const initFirm: Firm = {
  name: '',
  street_address: '',
  city: '',
  country: '',
  province: '',
  postal_code: '',
  email: '',
  gst_number: '',
  hst_number: '',
  pst_number: '',
  fiscal_start: 1,
};

export const initUserInfo: User & Login = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const STEP_ONE_TITLE = 'Step 1 of 2: Create Firm';
const STEP_TWO_TITLE = 'Step 2 of 2: Create default user';

export const CreateFirm = () => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [firmInfo, setFirmInfo] = useState<Firm>(initFirm);
  const [userInfo, setUserInfo] = useState<User & Login>(initUserInfo);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const { navigate } = useGetNavigation();
  const { updateDocumentTitle } = useUpdateDocumentTitle();

  const { createFirm } = useCreateFirm();

  const handleBackClick = () => {
    setIsSecondStep(false);
  };

  const fiscalStartCheck = (value) => {
    const fiscal_start = parseFloat(value);
    if (isNaN(fiscal_start)) {
      alert('Fiscal Start Year must be between 1 - 12');
      return;
    }

    if (
      fiscal_start < 1 ||
      fiscal_start > 12 ||
      !Number.isInteger(fiscal_start)
    ) {
      alert(
        'Fiscal Start Year must be a calendar month number, between 1 - 12'
      );
      return;
    }
    setFirmInfo({
      ...firmInfo,
      fiscal_start: fiscal_start as Fiscal_Start,
    });
  };

  console.log('firmInfo', firmInfo.postal_code);

  return (
    <div className="max-w-[420px] mx-auto">
      {isSecondStep ? (
        <div className="w-full mb-4">
          <button
            type="button"
            className="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto hover:bg-gray-100"
            onClick={handleBackClick}
          >
            <svg
              className="w-5 h-5 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span>Back to edit Create Firm step</span>
          </button>
        </div>
      ) : null}
      <div className="mx-auto ">
        <h1
          className="text-lg font-large leading-6 text-gray-800 capitalize"
          id="modal-title"
        >
          {!isSecondStep ? STEP_ONE_TITLE : STEP_TWO_TITLE}
        </h1>
        {!isSecondStep ? (
          <p className="italic py-2 text-base text-gray-800">
            This is your business or your firm. You will be creating a login
            user in Step 2.
          </p>
        ) : null}
      </div>
      <div className="max-w-[420px] mx-auto">
        {!isSecondStep ? (
          <div>
            <div>
              <label className="text-sm text-gray-700" htmlFor="name">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Business Name*"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  value={firmInfo.name}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      name: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      name: e.target.value,
                    });
                  }}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="email">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Company/Firm Email*"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  value={firmInfo.email}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      email: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      email: e.target.value,
                    });
                  }}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="fiscalStart">
                <input
                  type="number"
                  name="fiscalStart"
                  id="fiscalStart"
                  placeholder="Fiscal Start Year*"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    fiscalStartCheck(e.target.value);
                  }}
                  onChange={(e) => {
                    fiscalStartCheck(e.target.value);
                  }}
                  value={firmInfo.fiscal_start}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="gstNumber">
                <input
                  type="text"
                  name="gstNumber"
                  id="gstNumber"
                  placeholder="GST Number"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      gst_number: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      gst_number: e.target.value,
                    });
                  }}
                  value={firmInfo.gst_number}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="hstNumber">
                <input
                  type="text"
                  name="hstNumber"
                  id="hstNumber"
                  placeholder="HST Number"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      hst_number: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      hst_number: e.target.value,
                    });
                  }}
                  value={firmInfo.hst_number}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="pstNumber">
                <input
                  type="text"
                  name="pstNumber"
                  id="pstNumber"
                  placeholder="PST Number"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      pst_number: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      pst_number: e.target.value,
                    });
                  }}
                  value={firmInfo.pst_number}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="phone">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      phone: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      phone: e.target.value,
                    });
                  }}
                  value={firmInfo.phone}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="unit">
                <input
                  type="unit"
                  name="unit"
                  id="unit"
                  placeholder="Unit"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      unit: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      unit: e.target.value,
                    });
                  }}
                  value={firmInfo.unit}
                />
              </label>
              <label className="text-sm text-gray-700" htmlFor="street_address">
                <input
                  type="street_address"
                  name="street_address"
                  id="street_address"
                  placeholder="Street Address"
                  className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                  onBlur={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      street_address: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    setFirmInfo({
                      ...firmInfo,
                      street_address: e.target.value,
                    });
                  }}
                  value={firmInfo.street_address}
                />
              </label>
              <label
                className="text-sm text-gray-700"
                htmlFor="project-country"
              >
                <div className="mt-4">
                  <CountrySelect
                    onChange={(e) => {
                      setFirmInfo({
                        ...firmInfo,
                        country: e.name,
                      });
                      setCountryid(e.id);
                    }}
                    placeHolder="Select Country"
                    value={firmInfo.country}
                  />
                </div>
              </label>
              <label
                className="text-sm text-gray-700"
                htmlFor="project-province"
              >
                <div className="mt-4">
                  <StateSelect
                    countryid={countryid}
                    onChange={(e) => {
                      setFirmInfo({
                        ...firmInfo,
                        province: e.name,
                      });
                      setstateid(e.id);
                    }}
                    placeHolder="Select State"
                    value={firmInfo.province}
                  />
                </div>
              </label>
              <label className="text-sm text-gray-700" htmlFor="project-city">
                <div className="mt-4">
                  <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e) => {
                      setFirmInfo({
                        ...firmInfo,
                        city: e.name,
                      });
                      setCityid(e.id);
                    }}
                    placeHolder="Select City"
                    value={firmInfo.city}
                  />
                </div>
              </label>
              <label
                className="text-sm text-gray-700"
                htmlFor="project-postal_code"
              >
                <PostalCodeInput
                  isCanada={firmInfo?.country === 'Canada'}
                  label="project-postal_code"
                  initValue={firmInfo.postal_code}
                  updatePostalCode={(postalCode) => {
                    setFirmInfo({
                      ...firmInfo,
                      postal_code: postalCode,
                    });
                  }}
                />
              </label>
            </div>
            <div className="mt-4 sm:flex sm:items-center sm:-mx-2 flex flex-col">
              <div className="w-full flex justify-center">
                <button
                  type="button"
                  className="w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  onClick={() => {
                    if (firmInfo.name === '' || firmInfo.email === '') {
                      alert(
                        'You must add at your business/firm name and email to the the 2nd step'
                      );
                      return;
                    }
                    setIsSecondStep(true);
                  }}
                >
                  Create Firm
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <label className="text-sm text-gray-700" htmlFor="userFirstName">
              <input
                type="text"
                name="userFirstName"
                id="userFirstName"
                placeholder="First Name*"
                className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    firstName: e.target.value,
                  });
                }}
              />
            </label>
            <label className="text-sm text-gray-700" htmlFor="userLastName">
              <input
                type="text"
                name="userLastName"
                id="userLastName"
                placeholder="Last Name*"
                className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    lastName: e.target.value,
                  });
                }}
              />
            </label>
            <label className="text-sm text-gray-700" htmlFor="userEmail">
              <input
                type="email"
                name="userEmail"
                id="userEmail"
                placeholder="User Email*"
                className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    email: e.target.value,
                  });
                }}
              />
            </label>
            <label className="text-sm text-gray-700" htmlFor="userPassword">
              <input
                type="password"
                name="userPassword"
                id="userPassword"
                placeholder="Password*"
                className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    password: e.target.value,
                  });
                }}
              />
            </label>
            <button
              type="button"
              className="w-full px-4 py-2 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:w-full hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
              onClick={async () => {
                // TODO: Validation
                if (
                  userInfo.firstName === '' ||
                  userInfo.lastName === '' ||
                  userInfo.email === '' ||
                  userInfo.password === ''
                ) {
                  alert('You must fill out all the inputs to create a user');
                  return;
                }
                const create = {
                  firm: firmInfo,
                  user: userInfo,
                };
                try {
                  const response = await createFirm(create);

                  if (response.message !== 'Firm and user created') {
                    throw new Error(
                      'create-firm.tsx::create firm or user failed'
                    );
                  }

                  navigate('/projects');
                  updateDocumentTitle(response.firm.name);
                } catch (err) {
                  console.error('create-firm::error', err);
                  throw new Error('Error creating firm');
                }
              }}
            >
              Create default user
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
