import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Awarded } from 'state/types';
import { getApiUrl } from '../../utils';
import { projects } from './projects';

export type GetAwardedResponse = {
  awarded: Awarded;
};

const baseUrl = getApiUrl('api/awarded/');

export const awardeds = createApi({
  reducerPath: 'awardedApi',
  tagTypes: ['Awarded', 'FinancialData'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_X_API_KEY);

      return headers;
    },
    credentials: 'include',
    mode: 'cors',
  }),
  endpoints: ({ query, mutation }) => ({
    getAwarded: query<GetAwardedResponse, number>({
      query: (projectId) => {
        return `/${projectId}`;
      },
      providesTags: (_result, _error, id) => [{ type: 'Awarded', id }],
      keepUnusedDataFor: 0,
    }),
    createAwarded: mutation<
      Awarded,
      { awarded_amount: number; projectId: number }
    >({
      query: ({ awarded_amount, projectId }) => {
        return {
          url: `${projectId}`,
          method: 'POST',
          body: {
            awarded_amount,
            projectId,
          },
        };
      },
      onQueryStarted: async ({ awarded_amount, projectId }, api) => {
        try {
          await api.queryFulfilled;
          // api.dispatch(projects.util.invalidateTags([{ type: 'Projects', id: projectId }])) // This forces getAllProjects to be called again...
          api.dispatch(
            projects.util.updateQueryData(
              'getAllProjects',
              'placeholder',
              (draft) => {
                const project = draft.projects.find(
                  (project) => project.id === projectId
                );
                if (project) {
                  project.awarded = awarded_amount.toString();
                }
              }
            )
          );
        } catch (err) {
          alert(
            'Contact the administrator, there was an error getting the latest awarded data'
          );
          console.error('Error with getting latest awarded data');
        }
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: 'Awarded', id: projectId },
      ],
    }),
  }),
});

export const {
  useGetAwardedQuery,
  useLazyGetAwardedQuery,
  useCreateAwardedMutation,
} = awardeds;
export const awardedApi = awardeds.endpoints;
