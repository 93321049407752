import { useContext, useMemo, useRef, useState } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { FormattedProject, Project } from 'state/types';
import { useTableStates } from './use-table-states';
import { projectsColumns } from './projects-columns';
import {
  useCreateAwardedMutation,
  useUpdateProjectMutation,
} from '../../../state/services';
import { DataTableHandlersContext } from '../../../components/react-table/hooks';
import { prepProjectsForTable } from './utils';

export const useProjectsTable = ({
  data,
  projectTypes,
}: {
  data: Project[];
  projectTypes: string[];
}) => {
  const { updateRowsToReset } = useContext(DataTableHandlersContext);
  const [updateProject] = useUpdateProjectMutation();
  const [createAwarded] = useCreateAwardedMutation({
    fixedCacheKey: 'awarded',
  });
  const {
    menuPosition,
    setMenuPosition,
    selectedRow,
    setSelectedRow,
    editedRows,
    setEditedRows,
    // deletedRows,
    // setDeletedRows,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
  } = useTableStates<FormattedProject>();
  const [grouping, setGrouping] = useState<string[]>([]);
  const [siteAddressOpen, setSiteAddressOpen] = useState<number | null>(null);
  const [commentsOpen, setCommentsOpen] = useState<number | null>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const formattedData = useMemo(() => prepProjectsForTable(data), [data]);
  console.time('REACTTABLE');
  const table = useReactTable({
    data: formattedData,
    columns: projectsColumns,
    initialState: {
      sorting: [{ id: 'updated_at', desc: true }],
    },
    state: {
      columnFilters,
      columnVisibility,
      grouping,
    },
    defaultColumn: {
      minSize: 10,
      size: 10,
    },
    getRowId: (row) => String(row.id),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onGroupingChange: (newGrouping) => {
      setGrouping(newGrouping);
    },
    meta: {
      updateData: (rowId, columnId, value) => {
        setEditedRows((prev) => {
          const newValue = {
            ...prev,
            [rowId]: {
              ...prev[rowId],
              [columnId]: value,
            },
          };
          return newValue;
        });
      },
      addRow: () => {},
      deleteRow: () => {},
      optionsStore: {
        projectTypes: projectTypes.map((projectType) => ({
          label: projectType.toLowerCase(),
          value: projectType,
        })),
        statuses: [
          { value: 'ACTIVE', label: 'Active' },
          { value: 'PENDING PROPOSAL', label: 'Pending Proposal' },
          { value: 'PROPOSAL SENT', label: 'Proposal Sent' },
          { value: 'COMPLETE', label: 'Complete' },
          { value: 'LOST', label: 'Lost' },
        ],
      },
    },
  });
  console.timeEnd('REACTTABLE');

  const handleContextMenu = (
    e: React.MouseEvent<HTMLTableRowElement>,
    row: FormattedProject
  ) => {
    e.preventDefault();
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setSelectedRow(row);
  };

  const handleSave = async (rowId: string) => {
    const updatedRow = editedRows[rowId];
    const rowBeforeUpdate = data.find((row) => row.id === Number(rowId))!;
    const rowToSave = {
      ...rowBeforeUpdate,
      ...updatedRow,
    };

    if ('awarded' in updatedRow) {
      if (Number(rowBeforeUpdate?.awarded) !== updatedRow.awarded) {
        if (isNaN(Number(updatedRow.awarded))) {
          toast.error(
            `Something went wrong trying to update awarded value for ${rowToSave.project_number}. Please check the value and try again, or contact the system administrator.`
          );
          return;
        }

        try {
          await createAwarded({
            awarded_amount: Number(updatedRow.awarded),
            projectId: rowToSave.id,
          });
        } catch (err) {
          console.error(err);
          toast.error(
            `Failed to update Project #${rowToSave.project_number} when trying to save awarded. Please check your updates and try again.`
          );
        }
      }
    }

    try {
      await updateProject(rowToSave).unwrap();
      setEditedRows((prev) => {
        const newValue = { ...prev };
        delete newValue[rowId];
        return newValue;
      });
      toast.success(
        `Project #${rowToSave.project_number} has been updated successfully!`
      );
    } catch (err) {
      console.error(err);
      toast.error(
        `Failed to update Project #${rowToSave.project_number}. Please check your updates and try again.`
      );
    }
  };

  const handleReset = (rowId: string) => {
    setEditedRows((prev) => {
      const newValue = { ...prev };
      delete newValue[rowId];
      return newValue;
    });
    updateRowsToReset(Number(rowId));
    const foundProject = data.find((row) => row.id === Number(rowId))!;
    toast.info(
      `Changes to Project #${foundProject.project_number} have been reset. Your changes have not been saved.`
    );
  };

  const updateMenuPosition = (position: { x: number; y: number } | null) => {
    setMenuPosition(position);
  };

  return {
    formattedData,
    siteAddressOpen,
    updateSiteAddressOpen: (projectId: number | null) => {
      setSiteAddressOpen(projectId);
    },
    commentsOpen,
    updateCommentsOpen: (projectId: number | null) => {
      setCommentsOpen(projectId);
    },
    menuPosition,
    updateMenuPosition,
    editedIds: Object.keys(editedRows),
    table,
    selectedRow,
    updateSelectedRow: (row: FormattedProject) => {
      setSelectedRow(row);
    },
    handleContextMenu,
    handleSave,
    handleReset,
    tableContainerRef,
  };
};
