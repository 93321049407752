import React from 'react';
import { Bar } from 'react-chartjs-2';

import { type AverageDaysToPayment as AverageDaysToPaymentType } from 'state/services';

export const AverageDaysToPayment = ({
  data,
}: {
  data: AverageDaysToPaymentType[];
}) => {
  const config = {
    labels: data.map((item) => item.account_name),
    datasets: [
      {
        label: 'Average Days to Payment',
        data: data.map((item) =>
          parseFloat(parseFloat(item.avg_days_to_payment).toFixed(2))
        ),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
  };

  return (
    <div className="h-auto w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
