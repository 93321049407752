import React, { useState } from 'react';
import {
  BriefcaseBusiness,
  ChevronRightIcon,
  ChevronLeftIcon,
  LogIn,
  LogOut,
  User,
  FolderClosedIcon,
  UsersIcon,
  ChartColumnIcon,
  type LucideProps,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectFirm } from '../../state/services';
import { useGetNavigation } from '../../utils/hooks';
import { useAuth } from '../../hooks';
import {
  ALLOWED_ROLES,
  selectIsLoggedIn,
  selectUserType,
} from '../../state/services';
import { useNavContext } from './context/NavContext';

export const Sidebar = () => {
  const { isSidebarOpen, handleSidebarToggle } = useNavContext();
  const { navigate } = useGetNavigation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { handleLogoutAll } = useAuth();
  const userType = useSelector(selectUserType);
  const IS_EDITOR = !ALLOWED_ROLES.includes(userType);
  const navigation: {
    isAuth?: boolean;
    title: string;
    path: string;
    icon: React.ForwardRefExoticComponent<
      Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
    >;
  }[] =
    isLoggedIn && !IS_EDITOR
      ? [
          { title: 'Dashboard', path: '/dashboard', icon: ChartColumnIcon },
          { title: 'Projects', path: '/projects', icon: FolderClosedIcon },
          { title: 'Accounts', path: '/accounts', icon: UsersIcon },
        ]
      : [
          { title: 'Projects', path: '/projects', icon: FolderClosedIcon },
          { title: 'Accounts', path: '/accounts', icon: UsersIcon },
        ];

  if (isLoggedIn) {
    if (!IS_EDITOR) {
      navigation.push({
        title: 'Firm',
        path: '/firm',
        icon: BriefcaseBusiness,
      });
    }
    navigation.push({ title: 'User', path: '/user', icon: User });
  }

  navigation.push({
    isAuth: true,
    title: isLoggedIn ? 'Logout' : 'Login',
    path: isLoggedIn ? '/logout' : '/login',
    icon: isLoggedIn ? LogOut : LogIn,
  });

  return (
    <div
      className={`h-screen max-h-[calc(100% - 66px)] z-[20] fixed left-0 top-0 pb-4 pl-4 gap-8 pt-[80px] bg-[#041D15] text-white transition-all duration-300 flex flex-col items-start ${
        isSidebarOpen ? 'w-40' : 'w-16'
      }`}
    >
      <button
        className="rounded-full bg-gray-800 self-start"
        onClick={() => {
          handleSidebarToggle();
        }}
      >
        {isSidebarOpen ? (
          <ChevronLeftIcon className="text-white" />
        ) : (
          <ChevronRightIcon className="text-white" />
        )}
      </button>
      <nav className="flex flex-col gap-4 w-full">
        {navigation.map(({ title, isAuth, path, icon: Icon }) => (
          <div
            key={title}
            className="rounded w-full cursor-pointer transition-all duration-300"
          >
            <button
              className="flex items-center gap-2 "
              type="button"
              onClick={() => {
                if (isAuth) {
                  if (path === '/login') {
                    navigate(path);
                    return;
                  }
                  handleLogoutAll();
                  return;
                }
                navigate(path);
                if (isSidebarOpen) {
                  handleSidebarToggle(false);
                }
                return;
              }}
            >
              <Icon className="text-white" />
              <span
                className={`text-white ${
                  isSidebarOpen ? 'opacity-100' : 'opacity-0 w-0'
                }`}
              >
                {title}
              </span>
            </button>
          </div>
        ))}
      </nav>
    </div>
  );
};
