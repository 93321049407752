// import { EditorContent, useEditor } from '@tiptap/react';
// import StarterKit from '@tiptap/starter-kit';
import React, { useState } from 'react';
import { RichTextEditor } from '../../components/rich-text-editor';
import { Modal } from '../../shared';
import { useListenForKeyboardPress } from '../../utils/hooks';

// const extensions = [
//   StarterKit,
// ]

type Props = {
  title?: string;
  comments?: string;
  saveComments?: (comments: string) => void;
  closeModal: () => void;
};

export const CommentsModal = ({
  title,
  comments,
  saveComments,
  closeModal,
}: Props) => {
  useListenForKeyboardPress('Escape', () => {
    closeModal();
  });
  const [editedComments, setEditedComments] = useState(comments ?? '');

  return (
    <Modal
      title={`${title ? `${title}: ` : ''}Comments`}
      closeModal={closeModal}
    >
      <div className="w-full h-full">
        <div className="border border-black rounded-md mb-4 text-left">
          <RichTextEditor
            content={editedComments}
            updateContent={(content) => {
              setEditedComments(content);
            }}
          />
        </div>
        <div className="flex w-1/2 mx-auto items-center justify-center">
          {!!saveComments ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                saveComments(editedComments);
                closeModal();
              }}
              type="button"
              className="w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-emerald-800 rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            >
              Save Comments
            </button>
          ) : null}
          <button
            type="button"
            className={`${!!saveComments ? '' : '!mx-auto '}w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md`}
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
