import React from 'react';
import * as ReactAvatar from '@radix-ui/react-avatar';

export const Avatar = ({
  imgSrc,
  altValue,
}: {
  imgSrc?: string;
  altValue: string;
}) => {
  return (
    <div className="flex gap-4">
      <ReactAvatar.Root className="w-16 h-16 rounded-full overflow-hidden">
        <ReactAvatar.Image
          className="w-full h-full object-cover"
          src={imgSrc ?? ''}
          alt={altValue}
        />
        <ReactAvatar.Fallback
          className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-700"
          delayMs={600}
        >
          {altValue
            .split(' ')
            .map((word) => word.charAt(0))
            .join('')
            .toUpperCase()}
        </ReactAvatar.Fallback>
      </ReactAvatar.Root>
    </div>
  );
};
