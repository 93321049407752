import React, { memo, useMemo, Fragment, useContext } from 'react';
import type { MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { flexRender } from '@tanstack/react-table';
import './table-types';
import type {
  Table,
  RowData,
  Header,
  HeaderGroup,
  Row,
  Cell,
} from '@tanstack/react-table';
import {
  useVirtualizer,
  VirtualItem,
  Virtualizer,
} from '@tanstack/react-virtual';
import { BsSortUp, BsSortDownAlt } from 'react-icons/bs';
import { PiPushPin, PiPushPinFill } from 'react-icons/pi';
import FilterInput from './filter-input';
import { getCommonPinningStyles } from './utils';
import { DataTableContext } from './hooks';

export const EXPANDER = 'expander';

type ClickHandlers = {
  [id: string]: (id: number) => void;
};

const setExpanderAsFirstColumn = <TData,>(
  secondCol: Cell<TData, unknown> | Header<TData, unknown>,
  firstCol: Cell<TData, unknown> | Header<TData, unknown>
) => {
  if (
    firstCol.column ? firstCol.column.id === EXPANDER : firstCol.id === EXPANDER
  ) {
    return 1;
  }
  if (
    secondCol.column
      ? secondCol.column.id === EXPANDER
      : secondCol.id === EXPANDER
  ) {
    return -1;
  }
  return 0;
};

type RowComponentProps<TData> = {
  index: number;
  editedIds: string[];
  row: Row<TData>;
  onClickExpander?: (row: Row<TData>) => void;
  onContextMenu: (
    event: ReactMouseEvent<HTMLTableRowElement>,
    row: TData
  ) => void;
  addedNewSubRowId?: number;
  clickHandlers: ClickHandlers;
  virtualRow: VirtualItem;
  rowVirtualizer: Virtualizer<HTMLDivElement, HTMLTableRowElement>;
};

interface TableBodyRowProps<TData> {
  row: Row<TData>;
  index: number;
  editedIds: string[];
  onClickExpander?: (row: Row<TData>) => void;
  onContextMenu: (
    event: ReactMouseEvent<HTMLTableRowElement>,
    row: TData
  ) => void;
  addedNewSubRowId?: number;
  clickHandlers: ClickHandlers;
  virtualRow: VirtualItem;
  rowVirtualizer: Virtualizer<HTMLDivElement, HTMLTableRowElement>;
  selectedRowId?: string;
  onRowClick?: (row: TData) => void;
}

const TableBodyRow = <TData,>({
  row,
  index,
  editedIds,
  onClickExpander,
  onContextMenu,
  addedNewSubRowId,
  clickHandlers,
  virtualRow,
  rowVirtualizer,
  selectedRowId,
}: TableBodyRowProps<TData>) => {
  const { handleSave, handleReset } = useContext(DataTableContext);
  // const isHighlighted = selectedRowId === row.id;
  const isEdited = editedIds.includes(row.id);
  return (
    <>
      <tr
        data-index={virtualRow.index}
        ref={(node) => rowVirtualizer.measureElement(node)}
        key={row.id}
        className={`table-row table-primary-row border-gray-50 border-b-2${addedNewSubRowId === Number(row.id) ? ' table-secondary-row-new' : ''}`}
        onContextMenu={(e) => onContextMenu(e, row.original)}
        style={{
          display: 'flex',
          position: 'absolute',
          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
          width: '100%',
        }}
      >
        {
          // Ensure expander column is always first
          row
            .getVisibleCells()
            // .sort(setExpanderAsFirstColumn)
            .map((cell) => {
              const pinnedStyle = getCommonPinningStyles(cell.column) ?? {};
              return (
                <td
                  key={cell.id}
                  style={{
                    ...pinnedStyle,
                    display: 'flex',
                    width: `${cell.column.getSize()}%`,
                  }}
                  className={`${isEdited ? 'bg-sky-50' : 'bg-white'} flex items-center`}
                  // className={`${isEdited ? 'bg-sky-50' : index % 2 === 0 ? 'bg-zinc-100' : 'bg-white'}`}
                  onClick={() => {
                    if (cell.column.id === EXPANDER) {
                      onClickExpander?.(row);
                    }
                    if (!!clickHandlers[cell.column.id]) {
                      clickHandlers[cell.column.id](Number(cell.row.id));
                    }
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })
        }
        <td
          className={`sticky right-0 top-0 min-w-[132px] w-[132px] h-auto m-0 ${isEdited ? 'bg-sky-50' : 'bg-white'}`}
          // className={`sticky right-0 top-0 min-w-[132px] w-[132px] h-auto m-0 ${isEdited ? 'bg-sky-50' : index % 2 === 0 ? 'bg-zinc-100' : 'bg-white'}`}
        >
          <div className="flex gap-1 justify-center items-center">
            <button
              type="button"
              className={`text-sm relative inline-block px-2 py-1 duration-150 font-medium rounded-lg ${isEdited ? 'bg-emerald-800 text-white' : 'bg-gray-100 text-gray-500 cursor-not-allowed'}`}
              onClick={async () => {
                if (!isEdited) {
                  return;
                }
                await handleSave(row.id);
              }}
            >
              Save
            </button>
            <button
              type="button"
              className={`text-sm relative inline-block px-2 py-1 duration-150 font-medium rounded-lg ${isEdited ? 'bg-emerald-800 text-white' : 'bg-gray-100 text-gray-500 cursor-not-allowed'}`}
              onClick={() => {
                if (!isEdited) {
                  return;
                }
                handleReset(row.id);
              }}
            >
              Reset
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

type DataTableProps<TData> = {
  table: Table<TData>;
  editedIds: string[];
  selectedRowId?: string;
  onClickExpander?: (row: Row<TData>) => void;
  onRowClick?: (row: TData) => void;
  onContextMenu: (
    event: ReactMouseEvent<HTMLTableRowElement>,
    row: TData
  ) => void;
  tableClassName?: string;
  addedNewSubRowId?: number;
  clickHandlers?: ClickHandlers;
  children?: ReactNode;
  tableContainerRef: React.RefObject<HTMLDivElement>;
};

export const DataTable = <TData extends RowData>({
  table,
  editedIds,
  selectedRowId,
  onClickExpander,
  onContextMenu,
  onRowClick,
  tableClassName,
  addedNewSubRowId,
  clickHandlers,
  children,
  tableContainerRef,
}: DataTableProps<TData>) => {
  const allRows = table.getRowModel().rows;
  const depthZeroTable = allRows.filter((row) => row.depth === 0);

  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLTableRowElement>({
    count: depthZeroTable.length,
    estimateSize: () => 66,
    getScrollElement: () => {
      return tableContainerRef.current;
    },
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 25,
  });

  const getExpander = (headerGroup: HeaderGroup<TData>) =>
    headerGroup.headers.find((header) => header.id === EXPANDER)?.column;

  const handlePinClick = (header: Header<TData, unknown>) => {
    const prevPinnedValue =
      header.column.getIsPinned() === 'left' ? false : 'left';
    header.column.pin(prevPinnedValue);
  };

  return (
    // <div className="h-full overflow-scroll rounded-md">
    <table className={`table ${tableClassName ?? 'bg-white'} rounded-md`}>
      <thead className="sticky top-0 z-[1] bg-white w-full">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="w-full flex border-b border-gray-200"
          >
            {headerGroup.headers
              .sort(setExpanderAsFirstColumn)
              .map((header) => {
                const pinningStyles =
                  getCommonPinningStyles?.(header.column) ?? {};
                return (
                  <th
                    key={header.id}
                    style={{
                      ...pinningStyles,
                      width: `${header.getSize()}%`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div className="flex flex-col text-gray-400">
                        <div className="flex items-center justify-between pb-1">
                          <div
                            className="cursor-pointer"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </span>
                          </div>
                          <div
                            className="flex cursor-pointer select-none gap-1"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.column.id !== EXPANDER && (
                              <>
                                {header.column.getCanSort() && (
                                  <div>
                                    {header.column.getIsSorted() === 'asc' ? (
                                      <BsSortDownAlt />
                                    ) : (
                                      <BsSortUp />
                                    )}
                                  </div>
                                )}
                                {header.column.getIsPinned() === false ? (
                                  <PiPushPin
                                    onClick={() => {
                                      const expanderColumn =
                                        getExpander(headerGroup);
                                      if (
                                        expanderColumn &&
                                        expanderColumn.getIsPinned() !== 'left'
                                      ) {
                                        expanderColumn.pin('left');
                                      }
                                      handlePinClick(header);
                                    }}
                                  />
                                ) : (
                                  <PiPushPinFill
                                    onClick={() => {
                                      handlePinClick(header);
                                      const isAtLeastOnePinned = table
                                        .getAllColumns()
                                        .filter(
                                          (column) =>
                                            column.id !== header.id &&
                                            column.id !== EXPANDER
                                        )
                                        .some(
                                          (column) =>
                                            column.getIsPinned() !== false
                                        );
                                      if (!isAtLeastOnePinned) {
                                        getExpander(headerGroup)?.pin(false);
                                      }
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {header.column.getCanFilter() ? (
                          <div>
                            <FilterInput column={header.column} table={table} />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            <th className="sticky right-0 top-0 min-w-[132px] w-[132px] h-auto m-0 bg-white">
              <div className="w-full flex justify-center items-center">
                <span className="text-sm font-semibold">Actions</span>
              </div>
            </th>
          </tr>
        ))}
      </thead>
      {children}
      <tbody
        style={{
          // display: 'grid',
          height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
          position: 'relative', //needed for absolute positioning of rows
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
          const row = depthZeroTable[virtualRow.index] as Row<TData>;
          return (
            <TableBodyRow
              row={row}
              index={index}
              editedIds={editedIds}
              selectedRowId={selectedRowId}
              onClickExpander={onClickExpander}
              onContextMenu={onContextMenu}
              addedNewSubRowId={addedNewSubRowId}
              clickHandlers={clickHandlers}
              rowVirtualizer={rowVirtualizer}
              virtualRow={virtualRow}
              onRowClick={onRowClick}
            />
          );
        })}
      </tbody>
    </table>
    // </div>
  );
};
