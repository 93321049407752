import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Info } from '../../components/info/Info';
import { Avatar } from '../../components/avatar';
import { Loader } from '../../components/loader';
import { selectFirm, selectUser } from '../../state/services';
import { useGetAllUsersForFirmQuery } from '../../state/services/users';
import { createOptions, getApiUrl } from '../../utils';

type Role = 'admin' | 'user' | 'editor';

export const Firm = () => {
  // use ref and pass the value as part of arg in useGetAllUsersForFirmQuery to prevent caching
  // const timestampRef = useRef(Date.now()).current;
  const currentUser = useSelector(selectUser);
  const firm = useSelector(selectFirm);
  const { isError, isLoading, isSuccess, data } =
    useGetAllUsersForFirmQuery('firm-page');
  const allUsers = data;
  const [isEmailUiOpen, setIsEmailUiOpen] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState('');
  const [role, setRole] = useState<Role>('user');

  const isAdmin = currentUser.role === 'admin';

  const handleEditFirm = () => {
    alert('edit firm coming soon...');
  };

  const handleEditUser = () => {
    alert('edit user coming soon...');
  };

  const handleRoleChange = (role: Role) => {
    setRole(role);
  };

  const sendInvite = async () => {
    if (emailToInvite !== '') {
      const url = getApiUrl(`api/invite/${firm.id}`);
      const response = await fetch(
        url,
        createOptions('POST', {
          body: {
            invitation: {
              email: emailToInvite,
              role,
            },
          },
        })
      );

      if (!response.ok) {
        alert('There was an error, please try again');
        return;
      }

      // await response.json()
      setInviteSent(true);
      setTimeout(() => {
        setInviteSent(false);
        setIsEmailUiOpen(false);
      }, 5000);
    }
  };

  const handleAddNewUser = () => {
    setIsEmailUiOpen(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error. Please contact customer support</div>;
  }

  if (!isSuccess) {
    return <div>There was an error, please refresh the page to try again</div>;
  }

  return (
    <div className="w-full">
      <Info
        heading={{ key: 'Firm', value: firm.name }}
        information={{
          name: firm.name,
          ...(firm.unit ? { unit: firm.unit } : {}),
          ...(firm.street_address
            ? { 'street address': firm.street_address }
            : {}),
          ...(firm.city ? { city: firm.city } : {}),
          ...(firm.province ? { province: firm.province } : {}),
          ...(firm.country ? { country: firm.country } : {}),
          ...(firm.postal_code ? { 'Postal Code': firm.postal_code } : {}),
          ...(firm.email ? { email: firm.email } : {}),
          ...(firm.phone ? { phone: firm.phone } : {}),
          ...(firm.gst_number ? { gst_number: firm.gst_number } : {}),
          ...(firm.hst_number ? { hst_number: firm.hst_number } : {}),
          ...(firm.pst_number ? { pst_number: firm.pst_number } : {}),
        }}
        handleInfoEdit={isAdmin ? handleEditFirm : undefined}
      />
      <div className="max-w-screen-lg mx-auto px-4">
        {isAdmin ? (
          <div className="items-start justify-between sm:flex">
            <div className="flex flex-col items-start">
              <h4 className="text-gray-800 text-xl font-semibold">
                Team members
              </h4>
              <p className="mt-2 text-gray-600 text-base sm:text-sm">
                Give your team members access to manage the system.
              </p>
            </div>
            {isEmailUiOpen ? (
              <div className="flex gap-4">
                {inviteSent ? (
                  <div>
                    <p className="text-base text-left">
                      Your invite to {emailToInvite} was sent successfully.
                      Please ask to check their spam folder
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-sm text-gray-700"
                      htmlFor="invite-email"
                    >
                      <input
                        type="text"
                        name="invite-email"
                        id="invite-email"
                        placeholder="Email to invite"
                        className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                        onBlur={(e) => {
                          setEmailToInvite(e.target.value);
                        }}
                      />
                    </label>
                    <label
                      className="text-sm text-gray-700"
                      htmlFor="invite-user-role"
                    />
                    <select
                      className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        const role = e.target.value as Role;
                        handleRoleChange(role);
                      }}
                    >
                      <option value="admin" className="text-sm text-gray-700">
                        Admin
                      </option>
                      <option value="user" className="text-sm text-gray-700">
                        User
                      </option>
                      <option value="editor" className="text-sm text-gray-700">
                        Editor
                      </option>
                    </select>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center gap-1 py-2 px-3 font-medium text-sm text-center text-white bg-emerald-800 hover:bg-sky-700 active:bg-sky-500 rounded-lg sm:mt-0"
                      onClick={sendInvite}
                    >
                      Send invite
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                type="button"
                className="inline-flex items-center justify-center gap-1 py-2 px-3 mt-2 font-medium text-sm text-center text-white bg-emerald-800 hover:bg-sky-700 active:bg-sky-500 rounded-lg sm:mt-0"
                onClick={handleAddNewUser}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                New user
              </button>
            )}
          </div>
        ) : null}
        <ul className="mt-12 divide-y">
          {allUsers.map((item, idx) => (
            <li key={idx} className="py-5 flex items-start justify-between">
              <div className="flex justify-start gap-3">
                <Avatar
                  imgSrc=""
                  altValue={`${item.first_name} ${item.last_name}`}
                />
                <div className="flex flex-col items-start">
                  <span className="block text-sm text-gray-700">
                    <b>Name:</b> {`${item.first_name} ${item.last_name}`}
                  </span>
                  <span className="block text-sm text-gray-600">
                    <b>Email:</b> {item.email}
                  </span>
                  {isAdmin ? (
                    <span className="block text-sm text-gray-600 capitalize">
                      <b>Role type:</b> {item.role}
                    </span>
                  ) : null}
                </div>
              </div>
              {isAdmin ? (
                <button
                  type="button"
                  className="text-gray-700 text-sm border rounded-lg px-3 py-2 duration-150 bg-white hover:bg-gray-100"
                  onClick={handleEditUser}
                >
                  Edit user coming soon
                </button>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
