import React, { useEffect, useMemo, useState } from 'react';
import {
  selectFirm,
  selectIsLoggedIn,
  selectUser,
  selectUserType,
  useGetAllProjectsQuery,
} from '../../state/services';
import { useSelector } from 'react-redux';
import { DataTableHandlersContextProvider } from '../../components/react-table/hooks';
import {
  numberizeFeeAndAwarded,
  statusizeProjects,
} from './projects-test/utils';
import { ALLOWED_ROLES } from '../../state/services';
import { ProjectStatusCount } from 'state/types';
import { useProjectStatus } from './projects-test/use-project-status';
import { StatusBar } from './status-bar';
import { ProjectsTable } from './projects-test/projects-table';
import { AddProjectModal } from '../../components/add-project-modal';
import { currencyFormatter } from '../../utils';
import { Loader } from '../../components/loader';
import { FinancialOverview } from './financial-overview';

export const ProjectsTest = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const firm = useSelector(selectFirm);

  const userType = useSelector(selectUserType);
  const { status, updateStatus } = useProjectStatus();
  const [isLoadingInitialData, setIsLoadingInitalData] = useState(true);
  const {
    unique_project_types,
    projects,
    projectTypes,
    total_quarter_awarded,
    total_year_awarded,
    total_fee,
    isLoading,
    isSuccess,
    isFetching,
    status_count,
  } = useGetAllProjectsQuery(
    { status: 'ALL' },
    {
      skip: !isLoggedIn,
      selectFromResult: ({ data, isLoading, isFetching, isSuccess }) => {
        const defaultStatusCount: ProjectStatusCount = {
          ALL: 0,
          ACTIVE: 0,
          'PENDING PROPOSAL': 0,
          'PROPOSAL SENT': 0,
          COMPLETE: 0,
          LOST: 0,
        };

        if (data === undefined) {
          return {
            total_quarter_awarded: 0,
            total_year_awarded: 0,
            total_fee: 0,
            unique_project_types: [] as string[],
            projects: [],
            projectTypes: [],
            isLoading,
            isFetching,
            isSuccess,
            status_count: defaultStatusCount,
          };
        }

        const feesAndAwardedNumberizedProjects = numberizeFeeAndAwarded(
          data.projects ?? []
        );

        // TEMP: lowercase ALL PROJECT TYPES. This should be sanitized before going to DB...
        const lowercasedProjectTypes = feesAndAwardedNumberizedProjects.map(
          (project) => ({
            ...project,
            project_type: project.project_type.toLowerCase(),
          })
        );

        const projectTypes: string[] = lowercasedProjectTypes.reduce(
          (accum, projectData) => {
            if (
              !accum.some(
                (element) =>
                  element.toLowerCase() ===
                  projectData.project_type.toLowerCase()
              )
            ) {
              accum.push(projectData.project_type.toLowerCase());
            }
            return accum;
          },
          []
        );

        return {
          total_quarter_awarded: data.total_quarter_awarded,
          total_year_awarded: data.total_year_awarded,
          total_fee: data.total_fee,
          unique_project_types: data.unique_project_types,
          projects: lowercasedProjectTypes,
          projectTypes,
          isLoading,
          isFetching,
          isSuccess,
          status_count: {
            ...defaultStatusCount,
            ...data.status_count,
          },
        };
      },
    }
  );
  const [isCreateNewProjectsModalOpen, setIsCreateNewProjectsModalOpen] =
    useState(false);

  const { statusizedProjects, statuses } = useMemo(() => {
    return statusizeProjects(projects);
  }, [projects]);

  // Initial loading to show loader
  useEffect(() => {
    if (isSuccess) {
      setIsLoadingInitalData(false);
    }
  }, [isSuccess]);

  const currentStatusProjects = statusizedProjects[status];

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoggedIn || !user || !user.first_name || !user.last_name) {
    setTimeout(() => {
      window.location.href =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3000/login'
          : 'https://lampside.com/login';
    }, 3000);
    return <div>Not logged in. Redirecting to login...</div>;
  }

  if (!isSuccess && !isLoading && !isFetching) {
    return (
      <div>
        Failed to load data. Please try refreshing or contact customer support.
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col h-[100vh] w-full`}
      // className={`flex flex-col h-[100vh] w-full mt-[84px] ${isSidebarOpen ? 'ml-[64px]' : 'ml-[64px]'}`}
    >
      <main className="px-8">
        <div className="container-lg mx-auto h-auto">
          <div className="mx-2 flex h-auto flex-col gap-4">
            {ALLOWED_ROLES.includes(userType) ? (
              <FinancialOverview
                fiscal_start={firm.fiscal_start}
                total_quarter_awarded={total_quarter_awarded}
                total_year_awarded={total_year_awarded}
                total_fee={total_fee}
              />
            ) : null}
            <div className="bg-white flex h-auto flex-col rounded-lg bg-base-200 p-4 pt-1 shadow-xl">
              <div className="h-full overflow-hidden">
                <div className="flex flex-col overflow-hidden">
                  <div className="w-full flex justify-between items-center border-b pb-2">
                    <StatusBar
                      statuses={statuses}
                      status={status}
                      isEditingProjectRow={false}
                      projects={statusizedProjects}
                      handleStatusClick={(newStatus) => {
                        updateStatus(newStatus);
                      }}
                      statusCount={status_count}
                    />
                    <button
                      disabled={!isSuccess}
                      className={`w-auto flex justify-center py-2 px-4 items-center text-white text-sm duration-150 rounded-lg ${!isSuccess ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : 'bg-emerald-800 hover:bg-emerald-700 active:bg-emerald-500'}`}
                      type="button"
                      onClick={() => {
                        setIsCreateNewProjectsModalOpen(true);
                      }}
                    >
                      <span className="whitespace-nowrap">Add Project</span>
                    </button>
                  </div>
                  {/* Below prevented virtualization */}
                  {/* <div className="h-[65vh] overflow-auto rounded-md"> */}
                  {isLoadingInitialData ? (
                    // LOL this width is static
                    <div className="w-[95vw]">
                      {Array(10)
                        .fill(0)
                        .map(() => {
                          return (
                            <div
                              role="status"
                              className="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-sm shadow-sm animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                            >
                              <div className="flex items-center justify-between">
                                {Array(8)
                                  .fill(0)
                                  .map(() => {
                                    return (
                                      <div className="w-[20%] mx-4">
                                        <div className="h-2.5 bg-gray-300 rounded-full w-[100%] mb-2.5"></div>
                                        <div className="h-2 bg-gray-200 rounded-full w-[60%]"></div>
                                      </div>
                                    );
                                  })}
                                <div className="h-2.5 bg-gray-300 rounded-full w-12"></div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div className="overflow-auto rounded-md">
                      <DataTableHandlersContextProvider>
                        <ProjectsTable
                          projectTypes={projectTypes}
                          data={currentStatusProjects}
                          total_quarter_awarded={
                            total_quarter_awarded as number
                          }
                          total_year_awarded={total_year_awarded as number}
                        />
                      </DataTableHandlersContextProvider>
                    </div>
                  )}
                  {isCreateNewProjectsModalOpen ? (
                    <AddProjectModal
                      unique_project_types={unique_project_types}
                      closeModal={() => {
                        setIsCreateNewProjectsModalOpen(false);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
