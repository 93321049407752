import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = () => (
  <ToastContainer
    className="text-left"
    autoClose={3000}
    limit={3}
    // theme="colored"
  />
);
