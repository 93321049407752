import React, { createContext, ReactNode, useState } from "react"

type DataTableContextType = {
  handleSave: (rowId: string) => void
  handleReset: (rowId: string) => void
}

const defaultState: DataTableContextType = {
  handleSave: () => { },
  handleReset: () => { }
}

export const DataTableContext = createContext<DataTableContextType>(defaultState)

export const DataTableContextProvider = <T,>({ handleSave, handleReset, children }: { children: ReactNode; } & Omit<DataTableContextType, 'getRow' | 'updateRowsToReset'>) => {


  return (
    <DataTableContext.Provider value={{ handleSave, handleReset }}>
      {children}
    </DataTableContext.Provider>
  )
}

type DataTableHandlersType = {
  getRow: (rowId: number) => number
  updateRowsToReset: (rowId: number) => void
  removeRowFromReset: (rowId: number) => void
}

const defaultHandlers: DataTableHandlersType = {
  getRow: () => { return -1 },
  updateRowsToReset: () => { },
  removeRowFromReset: () => {},
}

export const DataTableHandlersContext = createContext(defaultHandlers)

export const DataTableHandlersContextProvider = ({ children }: { children: ReactNode }) => {
  const [rowsToReset, setRowsToReset] = useState<number[]>([])
  const updateRowsToReset = (rowId: number) => {
    setRowsToReset((prev) => {
      return [...prev, rowId]
    })
  }
  const removeRowFromReset = (rowId: number) => {
    setRowsToReset((prev) => {
      return prev.filter((id) => id !== rowId)
    })
  }
  const getRow = (rowId: number) => {
    return rowsToReset.find((id) => id === rowId) ?? -1;
  }
  return (
    <DataTableHandlersContext.Provider value={{ getRow, updateRowsToReset, removeRowFromReset, }}>
      {children}
    </DataTableHandlersContext.Provider>
  )
}