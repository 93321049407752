import React, { useState, useEffect, useMemo } from 'react';
import type { Column, Table } from '@tanstack/react-table';
import { Select, OptionType } from '../Select';
import type { MultiValue } from 'react-select';

type FilterInputProps<TData> = {
  column: Column<TData>;
  table: Table<TData>;
};

const FilterInput = <TData,>({ column, table }: FilterInputProps<TData>) => {
  const columnFilterValue = column.getFilterValue();
  const columnFilterValueArray = Array.isArray(columnFilterValue)
    ? columnFilterValue
    : [columnFilterValue];
  const { filterVariant } = column.columnDef.meta ?? {};

  const facetedValues = column.getFacetedUniqueValues();

  const uniqueValues = Array.from(facetedValues.keys())
    .map((value: string | number | boolean | null | undefined) =>
      value === '' || value === null || value === undefined ? null : value
    )
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort();

  const optionsKey = column.columnDef.meta?.optionsKey;
  const storedOptions = optionsKey
    ? (table.options.meta?.optionsStore?.[optionsKey] ?? [])
    : [];
  const options: OptionType[] = useMemo(() => {
    return uniqueValues.map((value: string | number | boolean | null) => {
      if (typeof value === 'boolean') {
        return {
          value,
          label: value ? 'Yes' : 'No',
        };
      }

      if (value === null) {
        return {
          value,
          label: '(Empty)',
        };
      }

      const matchedOption = storedOptions.find(
        (option: OptionType) => option.value === value
      );
      return {
        value,
        label: matchedOption ? matchedOption.label : String(value),
      };
    }) as OptionType[];
  }, [uniqueValues]);

  if (filterVariant === 'range') {
    return (
      <div>
        <div className="flex space-x-2">
          <DebouncedInput
            type="number"
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                value,
                old?.[1],
              ])
            }
            placeholder={`Min`}
            className="rounded border border-gray-300  bg-white px-3 py-2 leading-tight focus:outline-none"
          />
          <DebouncedInput
            type="number"
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                old?.[0],
                value,
              ])
            }
            placeholder={`Max`}
            className="rounded border border-gray-300  bg-white px-3 py-2 leading-tight focus:outline-none"
          />
        </div>
        <div className="h-1" />
      </div>
    );
  }

  if (filterVariant === 'select-boolean') {
    return (
      <Select
        isMulti
        options={options}
        value={options.filter((option) =>
          columnFilterValueArray.includes(option.value)
        )}
        onChange={(selectedOptions: MultiValue<OptionType>) => {
          const selectedValues = selectedOptions.map((option) => option.value);
          column.setFilterValue(
            selectedValues.length ? selectedValues : undefined
          );
        }}
        placeholder="Select..."
        isClearable
        includeToggleAll={true}
      />
    );
  }

  if (filterVariant === 'select-text') {
    return (
      <Select
        isMulti
        options={options}
        value={options.filter((option) =>
          columnFilterValueArray.includes(option.value)
        )}
        onChange={(selectedOptions: MultiValue<OptionType>) => {
          const selectedValues = selectedOptions.map((option) => option.value);
          column.setFilterValue(
            selectedValues.length ? selectedValues : undefined
          );
        }}
        placeholder="Select..."
        isClearable
        includeToggleAll={true}
      />
    );
  }

  return (
    <DebouncedInput
      className="w-auto h-[36px] w-full rounded border border-gray-200 bg-white px-2 py-1 text-[12px] font-normal leading-5 text-gray-700 placeholder-gray-500 transition duration-200 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
      onChange={(value) => {
        return column.setFilterValue(value);
      }}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  );
};

export default FilterInput;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
