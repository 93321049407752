import React, { createContext, useContext, ReactNode, useState } from 'react';

type NavContextType = {
  isSidebarOpen: boolean;
  handleSidebarToggle: (newState?: boolean) => void;
};

const defaultState: NavContextType = {
  isSidebarOpen: false,
  handleSidebarToggle: () => {},
};

export const NavContext = createContext<NavContextType>(defaultState);

export const NavContextProvider = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <NavContext.Provider
      value={{
        isSidebarOpen,
        handleSidebarToggle: (newState?: boolean) => {
          setIsSidebarOpen(newState ?? !isSidebarOpen);
        },
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNavContext must be used within a NavContextProvider');
  }
  return context;
};
