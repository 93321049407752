import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Project } from 'state/types';
import { useProjectsTable } from './use-projects-table';
import { DataTableContextProvider } from '../../../components/react-table/hooks';
import { DataTable } from '../../../components/react-table';
import { InvoiceAndPaymentsModal } from '../invoice/invoice-and-payments-modal';
import { RowMenu } from '../../../components/react-table/row-menu';
import { useListenForKeyboardPress } from '../../../utils/hooks';
import { EditAddressModal } from '../edit-address-modal';
import { formatToSiteAddress, type Address } from './utils';
import {
  useLazyGetProjectByIdQuery,
  useUpdateProjectMutation,
} from '../../../state/services';
import { CommentsModal } from '../comments-modal';

export const ProjectsTable = ({
  data,
  projectTypes,
  total_quarter_awarded,
  total_year_awarded,
}: {
  data: Project[];
  projectTypes: string[];
  total_quarter_awarded: number;
  total_year_awarded: number;
}) => {
  const {
    formattedData,
    table,
    editedIds,
    selectedRow,
    menuPosition,
    updateMenuPosition,
    updateSelectedRow,
    handleContextMenu,
    handleSave,
    handleReset,
    siteAddressOpen,
    updateSiteAddressOpen,
    commentsOpen,
    updateCommentsOpen,
    tableContainerRef,
  } = useProjectsTable({ data, projectTypes });
  const [isInvoiceAndPaymentsModalOpen, setIsInvoiceAndPaymentsModalOpen] =
    useState(false);

  const [updateProject] = useUpdateProjectMutation();

  useListenForKeyboardPress('Escape', () => {
    updateMenuPosition(null);
  });
  useEffect(() => {
    const handleClickOutsideRowMenu = () => {
      updateMenuPosition(null);
    };
    window.addEventListener('click', handleClickOutsideRowMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideRowMenu);
    };
  }, []);

  const handleClickSiteAddress = (projectId: number) => {
    updateSiteAddressOpen(projectId);
  };

  const handleClickComments = (projectId: number) => {
    updateCommentsOpen(projectId);
  };

  const handleSaveAddress = async (address: Address) => {
    try {
      // TODO: SHOULD ONLY SEND UPDATED PROPERTIES, NOT ENTIRE PROJECT OBJ. CHANGE BACKEND TO ACCEPT PATCH REQUEST
      const { unit, street_address, city, province, country, postal_code } =
        await updateProject({
          ...formattedData.find((project) => project.id === siteAddressOpen),
          ...address,
        }).unwrap();

      toast.success(
        `Address updated successfully to ${
          formatToSiteAddress({
            unit,
            street_address,
            city,
            province,
            country,
            postal_code,
          }).site_address
        }`
      );
    } catch (err) {
      console.error(err);
      toast.error(
        `Failed to update address. Please check your values and try again, or contact customer support.`
      );
    }
  };

  const handleSaveComments = async (comments: string) => {
    try {
      await updateProject({
        ...formattedData.find((project) => project.id === commentsOpen),
        comments,
      }).unwrap();

      toast.success(
        `Comments updatese successfully for ${formattedData.find((project) => project.id === commentsOpen).site_address}`
      );
    } catch (err) {
      console.error(err);
      toast.error(
        `Failed to edit comments. Please check your values and try again, or contact customer support.`
      );
    }
  };

  return (
    <DataTableContextProvider handleSave={handleSave} handleReset={handleReset}>
      <div
        ref={tableContainerRef}
        style={{
          overflow: 'auto', // scrollable table container
          position: 'relative', // needed for sticky header
          height: '80vh',
        }}
      >
        <DataTable
          editedIds={editedIds}
          table={table}
          selectedRowId={String(selectedRow?.id) ?? ''}
          onContextMenu={handleContextMenu}
          onRowClick={updateSelectedRow}
          // tableClassName={`table bg-white w-[${table.getTotalSize()}px]`}
          // tableClassName={`w-[90%]`}
          clickHandlers={{
            site_address: handleClickSiteAddress,
            comments: handleClickComments,
          }}
          tableContainerRef={tableContainerRef}
        ></DataTable>
      </div>
      {selectedRow !== null && menuPosition && (
        <>
          <RowMenu
            x={menuPosition.x}
            y={menuPosition.y}
            openInvoiceModal={() => {
              setIsInvoiceAndPaymentsModalOpen(true);
              updateMenuPosition(menuPosition);
            }}
          />
        </>
      )}
      {isInvoiceAndPaymentsModalOpen && selectedRow && (
        <InvoiceAndPaymentsModal
          // might need to use table.getRows.original map
          projects={data}
          projectId={selectedRow.id}
          closeModal={() => {
            setIsInvoiceAndPaymentsModalOpen(false);
            updateSelectedRow(null);
          }}
        />
      )}
      {!!formattedData.find((project) => project.id === siteAddressOpen) && (
        <EditAddressModal
          project={formattedData.find(
            (project) => project.id === siteAddressOpen
          )}
          saveAddress={handleSaveAddress}
          closeModal={() => {
            updateSiteAddressOpen(null);
          }}
        />
      )}
      {!!formattedData.find((project) => project.id === commentsOpen) && (
        <CommentsModal
          title={
            formattedData.find((project) => project.id === commentsOpen).site
          }
          comments={
            formattedData.find((project) => project.id === commentsOpen)
              .comments
          }
          saveComments={handleSaveComments}
          closeModal={() => {
            updateCommentsOpen(null);
          }}
        />
      )}
    </DataTableContextProvider>
  );
};
