import React, { useEffect, useState } from 'react';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from 'react-country-state-city';
import { Account } from 'state/global';
import { Modal } from '../../shared';
import {
  useEditAccountMutation,
  type UpdateAccountPayload,
} from '../../state/services';
import { populateCountryProvinceCity } from '../../utils';
import { PostalCodeInput } from '../location';

import 'react-country-state-city/dist/react-country-state-city.css';

type Props = {
  account: Account;
  closeModal: () => void;
};

export const EditAccountModal = ({ account, closeModal }: Props) => {
  const initData = {
    name: account.name,
    poc: account.poc,
    street_address: account.street_address,
    unit: account.unit,
    city: account.city,
    province: account.province,
    country: account.country,
    postal_code: account.postal_code,
    email: account.email,
    phone: account.phone,
  };
  const [accountDataToEdit, setAccountDataToEdit] =
    useState<UpdateAccountPayload['data']>(initData);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [countryObject, setCountryObject] = useState({ id: 0 });
  const [stateObject, setStateObject] = useState({ id: 0 });
  const [cityObject, setCityObject] = useState({ id: 0 });
  const [editAccount, editAccountResult] = useEditAccountMutation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    populateCountryProvinceCity({
      country: account.country,
      province: account.province,
      city: account.city,
      setCountry: setCountryObject,
      setProvince: setStateObject,
      setCity: setCityObject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(initData) !== JSON.stringify(accountDataToEdit)) {
      setIsSaveEnabled(true);
    }
  }, [accountDataToEdit]);

  if (editAccountResult.isError) {
    if (errorMessage === '') {
      setErrorMessage(
        'There was an error trying to update the Account information. Please try again or contact customer support.'
      );
    }
  }

  if (editAccountResult.isSuccess) {
    setTimeout(() => {
      closeModal();
    }, 100);
  }

  return (
    <Modal
      title={`Edit account: ${account.name}`}
      closeModal={() => {
        closeModal();
      }}
    >
      <div className="sm:w-[200px] md:w-[375px] lg:w-[500px]">
        <label className="text-sm text-gray-700" htmlFor="account-name">
          <input
            type="text"
            name="account-name"
            id="account-name"
            value={accountDataToEdit.name}
            className="block w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            onChange={({ target: { value } }) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                name: value,
              });
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="account-poc">
          <input
            type="text"
            name="account-poc"
            id="account-poc"
            value={accountDataToEdit.poc}
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            onChange={({ target: { value } }) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                poc: value,
              });
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="account-phone">
          <input
            type="text"
            name="account-phone"
            id="account-phone"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            value={accountDataToEdit.phone}
            onChange={(e) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                phone: e.target.value,
              });
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="account-email">
          <input
            type="email"
            name="account-email"
            id="account-email"
            placeholder="Email here"
            value={accountDataToEdit.email}
            onChange={(e) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                email: e.target.value,
              });
            }}
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="account-unit">
          <input
            type="text"
            name="account-unit"
            id="account-unit"
            placeholder="Unit"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            value={accountDataToEdit.unit}
            onChange={(e) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                unit: e.target.value,
              });
            }}
          />
        </label>
        <label
          className="text-sm text-gray-700"
          htmlFor="account-street_address"
        >
          <input
            type="text"
            name="account-street_address"
            id="account-street_address"
            className="block w-full px-4 py-3 mt-4 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            value={accountDataToEdit.street_address}
            onChange={(e) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                street_address: e.target.value,
              });
            }}
          />
        </label>
        <label className="text-sm text-gray-700" htmlFor="country">
          <div className="mt-4">
            <CountrySelect
              id="edit-account-country"
              defaultValue={countryObject?.id === 0 ? undefined : countryObject}
              onChange={(e) => {
                setAccountDataToEdit({
                  ...accountDataToEdit,
                  country: e.name,
                });
                setCountryObject(e);
              }}
              va
              placeHolder="Select Country"
            />
          </div>
        </label>
        <label className="text-sm text-gray-700" htmlFor="province">
          <div className="mt-4">
            <StateSelect
              countryid={countryObject.id}
              defaultValue={stateObject?.id === 0 ? undefined : stateObject}
              onChange={(e) => {
                setAccountDataToEdit({
                  ...accountDataToEdit,
                  province: e.name,
                });
                setStateObject(e);
              }}
              placeHolder="Select State"
            />
          </div>
        </label>
        <label className="text-sm text-gray-700" htmlFor="city">
          <div className="mt-4">
            <CitySelect
              countryid={countryObject.id}
              stateid={stateObject.id}
              defaultValue={cityObject?.id === 0 ? undefined : cityObject}
              onChange={(e) => {
                setAccountDataToEdit({
                  ...accountDataToEdit,
                  city: e.name,
                });
                setCityObject(e);
              }}
              placeHolder="Select City"
            />
          </div>
        </label>
        <label className="text-sm text-gray-700" htmlFor="postal_code">
          <PostalCodeInput
            isCanada={accountDataToEdit?.country === 'Canada'}
            label="project-postal_code"
            updatePostalCode={(postalCode) => {
              setAccountDataToEdit({
                ...accountDataToEdit,
                postal_code: postalCode,
              });
            }}
          />
        </label>
        <div className="mt-4 sm:flex sm:items-center sm:-mx-2">
          <button
            onClick={() => {
              editAccount({
                id: account.id,
                data: accountDataToEdit,
              });
            }}
            type="button"
            disabled={!isSaveEnabled}
            className={`${isSaveEnabled ? 'bg-emerald-800 hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40' : 'cursor-not-allowed bg-gray-300'} w-full px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-md sm:mt-0 sm:w-1/2 sm:mx-2`}
          >
            Save
          </button>
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
          >
            Cancel
          </button>
        </div>
        {/* TODO: REFACTOR FOR BETTER UI */}
        {errorMessage !== '' ? (
          <div>
            <span className="text-red-500 text-base">{errorMessage}</span>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
