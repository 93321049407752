import type { CSSProperties } from "react";
import type { Column } from "@tanstack/react-table";

export const getCommonPinningStyles = <TData>(column: Column<TData>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");
  const pinnedLeftStyle = column.getStart("left");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,
    left: isPinned === "left" ? `${pinnedLeftStyle}px` : undefined,
    right: 0,
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    background: isPinned ? "#ffffff" : undefined,
    position: isPinned ? "sticky" : undefined,
  };
};
