import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { type PaymentsPerDay as PaymentsPerDayType } from 'state/services';
import { currencyFormatter } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const PaymentsPerDay = ({ data }: { data: PaymentsPerDayType[] }) => {
  const labels = data.map((item) => item.payment_date);
  const datasets = [
    {
      label: 'Payments Per Day (last 30 days)',
      data: data.map(({ payment_date, ...rest }) => rest.payments_count),
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ];

  const config = {
    labels,
    datasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            // const label = context.dataset.label || '';
            // const value = context.raw;
            // const additionalInfo = Object.values(
            //   data[context.dataIndex].accounts
            // )
            //   .map(
            //     (account: {
            //       projects: { project_site: string; payment_amount: string }[];
            //     }) => account.projects.map((p) => p.project_site).join(', ')
            //   )
            //   .join('; ');

            const totalPaymentAmount =
              data[context.dataIndex].total_payment_amount;
            return `Total Payment Amount: ${currencyFormatter().format(totalPaymentAmount)}`;
          },
        },
      },
    },
  };

  return (
    <div className="h-auto w-full">
      <Bar data={config} options={options} />
    </div>
  );
};
