import React from 'react';
import { BallTriangle } from 'react-loader-spinner';

export const Loader = () => {
  return (
    <div
      role="status"
      className="absolute inset-0 flex flex-col items-center justify-center"
    >
      <BallTriangle
        height={60}
        width={60}
        radius={5}
        color="#01382D"
        ariaLabel="ball-triangle-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
